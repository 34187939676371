<template>
  <BaseModal
    v-if="showModal"
    :heading="$t('treatwell.intent_reminder.heading')"
    small
    testId="treatwell-intent-reminder"
    @close="dismiss"
  >
    <div :class="$style.description">
      <div>
        <BaseText mb>
          {{ $t('treatwell.intent_reminder.description') }}
        </BaseText>
      </div>
      <div>
        <div
          v-for="reason in ['reason_1', 'reason_2', 'reason_3']"
          :key="reason"
          :class="$style.bulletPoint"
        >
          <BaseIcon name="check" color="success" size="l" :mr="0.5" />
          {{ $t(`treatwell.intent_reminder.${reason}`) }}
        </div>
      </div>
    </div>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="revokeTreatwellIntent"
        v-test="'tw-revoke-intent'"
      >
        {{ $t('treatwell.intent_reminder.no_longer_interested') }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        @click="openContractModal"
        v-test="'intent-submit'"
      >
        {{ $t('treatwell.intent_reminder.get_started') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { computed, inject, onMounted } from 'vue';
import { useTreatwellStore } from '@/stores/treatwell';
import dayjs from '@/dayjs';
import { useStorage } from '@vueuse/core';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { UPDATE_TREATWELL_INTENT } from '../graphql';
import { useCompanyStore } from '@/stores/company';
import { useRouter } from 'vue-router';

const { isSubscribed } = useCompanyStore();
const { treatwellStatus, showTreatwell } = useTreatwellStore();

const mixpanel = inject<any>('mixpanel');
const dismissedAt = useStorage('twIntentReminderDismissedAt', '');
const router = useRouter();

const showModal = computed(
  () =>
    showTreatwell &&
    treatwellStatus.subscriptionIntention &&
    !treatwellStatus.contractSigned &&
    isSubscribed &&
    (!dismissedAt.value ||
      dayjs().subtract(3, 'day').isAfter(dismissedAt.value))
);

onMounted(() => {
  if (showModal.value) {
    mixpanel.track('TW - intent reminder - intent modal shown');
  }
});

const { mutate: revokeTreatwellIntent, onDone } = useMutation(
  UPDATE_TREATWELL_INTENT,
  {
    variables: {
      input: {
        subscriptionIntention: false
      }
    }
  }
);

onDone(() => {
  mixpanel.track('TW - intent reminder - revoked intent');
  dismiss();
});

const { mutate, loading } = useMutation(
  gql`
    mutation treatwellIntegrationRequestCreate(
      $input: IntegrationRequestCreateInput!
    ) {
      treatwellIntegrationRequestCreate(input: $input) {
        treatwellIntegrationRequest {
          id
        }
      }
    }
  `,
  {
    variables: {
      input: {}
    }
  }
);

const goToContract = () => {
  router.push({ name: 'treatwell-admin' });
};

const openContractModal = () => {
  mixpanel.track('TW - intent reminder - get started clicked');
  if (!treatwellStatus.hasRequest) {
    mutate().then(() => {
      dismiss();
      goToContract();
      treatwellStatus.hasRequest = true;
    });
  } else {
    dismiss();
    goToContract();
  }
};

const dismiss = () => (dismissedAt.value = dayjs().format());
</script>

<style lang="scss" module>
.bulletPoint {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
</style>
