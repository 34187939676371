import { useCompanyStore } from '@/stores/company';
import { redirectRoute } from '../helpers';

const Messages = () => import('@/modules/messages/index.vue');
const MessageLogs = () => import('@/modules/messages/logs/index.vue');
const Message = () => import('@/modules/messages/logs/modals/Message.vue');
const EmailTemplates = () =>
  import('@/modules/messages/email-templates/index.vue');
const EmailTemplate = () =>
  import('@/modules/messages/email-templates/template/index.vue');
const SMS = () => import('@/modules/messages/sms/index.vue');
const SmsTemplate = () => import('@/modules/messages/sms/Template.vue');
const PurchaseSmsCredits = () => import('@/modals/PurchaseSmsCredits.vue');

export default {
  path: 'messages',
  name: 'messages',
  component: Messages,
  redirect: {
    name: 'message-logs'
  },
  meta: {
    moduleName: 'messages',
    role: 'basic'
  },
  children: [
    {
      path: 'logs',
      name: 'message-logs',
      component: MessageLogs,
      children: [
        {
          path: ':messageId',
          name: 'message',
          component: Message
        }
      ]
    },
    {
      path: 'emails',
      name: 'message-templates',
      component: EmailTemplates,
      meta: {
        featureFlag: 'admin-communication'
      },
      children: [
        {
          path: ':id',
          name: 'message-template',
          component: EmailTemplate,

          beforeEnter: (to) => {
            const { company } = useCompanyStore();

            if (!company.contactEmailVerified) {
              return redirectRoute({
                name: 'message-templates',
                query: to.query
              });
            }
          }
        }
      ]
    },
    {
      path: 'sms',
      name: 'messages-sms',
      component: SMS,
      meta: {
        featureFlag: 'admin-communication'
      },
      children: [
        {
          path: ':id',
          name: 'messages-sms-template',
          component: SmsTemplate
        },
        {
          path: 'purchase-credits',
          name: 'messages-purchase-credits',
          component: PurchaseSmsCredits,
          meta: {
            featureFlag: 'sms-credits'
          },
          props: {
            parentRouteName: 'messages-sms'
          }
        }
      ]
    }
  ]
};
