<template>
  <ModuleBase
    noBorder
    gray
    :loading="!allDataLoaded || getServiceGroupsLoading"
  >
    <PageWrap wide :heading="$t('admin.nav.links.services')">
      <template #header>
        <BaseButton
          :options="[
            {
              label: $t('services.add_category'),
              routerLink: {
                name: 'admin-service-category-new'
              }
            },
            {
              label: $t('services.add_service'),
              routerLink: {
                name: 'admin-service-new'
              }
            }
          ]"
          v-test="'btn-add-new'"
        >
          {{ $t('global.actions.create_new') }}
        </BaseButton>
      </template>
      <PageHeader v-if="!sortReorder">
        <template #left>
          <BaseDropdown
            v-model="filterValue"
            :options="[
              {
                value: 'all',
                label: $t('services.filter.all')
              },
              {
                value: 'bookable',
                label: $t('services.filter.bookable_online')
              },
              {
                value: 'not_bookable',
                label: $t('services.filter.not_bookable_online')
              }
            ]"
            v-test="'service-filter'"
          />
          <BaseSearch v-model="searchQuery" v-test="'service-search'" />
        </template>
        <template #right>
          <BaseButton
            icon="swap-vertical"
            color="inverted"
            @click="sortReorder = true"
            v-test="'btn-sort-all'"
          />
        </template>
      </PageHeader>
      <BaseAlert
        v-if="sortReorder"
        icon="flip"
        :text="$t('services.reorder_services')"
        :primaryAction="$t('global.done')"
        mb
        @primaryAction="sortReorder = false"
      />
      <ServiceVariationsBanner mb />
      <List
        v-if="allDataLoaded && !getServiceGroupsLoading"
        :data="filteredCategoriesWithServices"
        :activeId="activeId"
        @toggle="(id) => (activeId = activeId === id ? null : id)"
      />
      <div
        :class="$style.addNewCategory"
        @click="$router.push({ name: 'admin-service-category-new' })"
        v-test="'service-category-create-list-bottom'"
      >
        <BaseIcon name="plus" color="success" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.create_category') }}
        </BaseText>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import PageHeader from '@/modules/PageHeader.vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import ServiceVariationsBanner from '@/modules/admin/_shared/ServiceVariationsBanner.vue';
import { useSessionStore } from '@/stores/session';
import PageWrap from '../PageWrap.vue';

import List from './list/index.vue';
import { useServiceGroupsStore } from '@/stores/service-groups';

const { allDataLoaded } = storeToRefs(useSessionStore());

const { categoriesWithServices, getServiceGroupsLoading } = storeToRefs(
  useServiceGroupsStore()
);

type BookableFilter = 'all' | 'bookable' | 'not_bookable';

const filterValue = ref<BookableFilter>('all');
const searchQuery = ref('');
const activeId = ref(null);
const sortReorder = ref(false);

provide('services-category-sort-order', sortReorder);

const filterByNameOrDescription = (
  item: { name: string; description?: string | null },
  searchQuery: string
) =>
  !!(
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (item.description &&
      item.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

const filterByOnlineBookable = (item: { services: { bookable: boolean }[] }) =>
  filterValue.value === 'all'
    ? true
    : !!(filterValue.value === 'bookable'
        ? item.services[0].bookable
        : !item.services[0].bookable);

const filteredCategoriesWithServices = computed(() =>
  categoriesWithServices.value
    .map((category) => ({
      ...category,
      services: category.services.filter(
        (service) =>
          filterByOnlineBookable(service) &&
          (filterByNameOrDescription(service, searchQuery.value) ||
            filterByNameOrDescription(category, searchQuery.value))
      )
    }))
    .filter((category) =>
      searchQuery.value !== ''
        ? category.services.length
        : category.services.length ||
          filterByNameOrDescription(category, searchQuery.value)
    )
);
</script>

<style lang="scss" module>
.addNewCategory {
  cursor: pointer;
  border: 1px dashed $color-success;
  display: flex;
  margin-top: $spacing * 0.25;
  padding: $spacing;
  border-radius: $radius;
}
</style>
