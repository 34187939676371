<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isLoading]: $apollo.loading
      }
    ]"
  >
    <BaseSpinner v-if="$apollo.loading" />
    <div v-else-if="events && events.length">
      <ScheduleItem
        v-for="(data, index) in events"
        :key="index"
        :data="data"
        v-test="'schedule-item'"
      />
    </div>
    <div v-else :class="$style.empty" v-test="'empty-schedule'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 301 225"
        :class="$style.visual"
        v-test="'empty-schedule-visual'"
      >
        <g :class="$style.visualPerson">
          <path
            fill="#FF6575"
            fill-rule="evenodd"
            d="M155.013 71.173a289.368 289.368 0 0 1-38.376-9.772c-7.047-2.32-14.138-4.936-21.552-5.252-.944-.04-1.926-.035-2.784.36-1.888.87-2.492 3.243-2.6 5.316-.301 5.756 2.26 12.335 7.775 14.035 1.36.42 2.795.511 4.214.598l50.024 3.091c.451.028.928.05 1.327-.16.493-.263.745-.814.964-1.329 1.009-2.367 1.866-5.498-.074-7.19"
            clip-rule="evenodd"
          />
          <path
            fill="#B72E6C"
            fill-rule="evenodd"
            d="M127.028 78.024c2.936-3.776 5.167-7.053 7.688-11.117-9.656-3.072-24.605-7.669-24.869-7.383-3.445 3.747-7.806 16.983-3.498 17.325.2.016 20.679 1.175 20.679 1.175Z"
            clip-rule="evenodd"
          />
          <path
            fill="#000"
            fill-rule="evenodd"
            d="M185.526 36.148a78.413 78.413 0 0 0-27.011 16.19c-1.774 1.629-3.511 3.394-4.486 5.595-1.362 3.075-1.078 6.588-.961 9.949.478 13.78-2.377 32.356-8.255 44.833-.499 1.06-1.031 2.26-.628 3.36.29.795 1.007 1.343 1.691 1.841 9.016 6.553 18.776 7.61 29.47 10.765 10.694 3.154 22.428 3.573 32.87-.335 3.183-1.191 6.208-2.764 9.181-4.411 6.34-3.512 12.8-7.722 15.939-14.247.609-1.267 1.086-2.634 1.074-4.038-.022-2.674-1.761-4.981-3.306-7.165a73.512 73.512 0 0 1-9.772-19.371c-2.086-6.321-3.341-12.994-6.583-18.81-3.319-5.953-8.684-10.744-14.979-13.375-3.811-1.593-8.303-2.693-10.431-6.23-.881-1.466-1.293-3.282-2.617-4.366-1.324-1.084-4.021-.41-3.754 1.278"
            clip-rule="evenodd"
          />
          <path
            fill="#98A2EE"
            fill-rule="evenodd"
            d="M146.086 156.356c-21.069-21.741-41.093-44.498-59.795-68.301-2.632-3.35-5.316-6.89-6.114-11.072-.796-4.181.938-9.17 4.93-10.665 5.146-1.928 10.269 2.563 14.027 6.568a347.774 347.774 0 0 0 57.033 48.886c2.259 1.546 4.713 3.315 5.321 5.981.323 1.415.066 2.891-.225 4.313a89.913 89.913 0 0 1-6.467 19.655c-1.56 3.374-2.436 16.803-5.543 14.758M282.347 42.03c-18.076 18.288-25.71 44.772-43.181 63.636-6.007 6.485-15.116 12.294-22.916 16.459 2.308 13.644 6.63 26.951 8.938 40.595 23.824-31.253 52.164-60.068 67.134-96.39.661-1.606 1.306-3.286 1.208-5.02-.078-1.367-.613-2.662-1.151-3.92a280.253 280.253 0 0 0-4.691-10.404c-1.171-2.462-3.271-5.346-5.903-4.625"
            clip-rule="evenodd"
          />
          <path
            fill="#948CDC"
            fill-rule="evenodd"
            d="M262.069 70.005c2.368 2.02 5.124 4.079 8.233 3.874 3.444-.228 6.056-3.157 7.857-6.097 4.216-6.885 6.198-15.111 5.581-23.158-.051-.648-.235-1.442-.865-1.602-.509-.129-.998.25-1.362.629-1.593 1.648-2.658 3.717-3.771 5.72a99.168 99.168 0 0 1-15.223 20.577m-108.523 84.078a73.642 73.642 0 0 0-13.387-21.605c-1.828-2.048-3.859-4.053-6.435-5.007-.393-.145-.845-.261-1.217-.068-.582.301-.591 1.118-.502 1.766 1.471 10.597 16.68 33.607 19.755 31.285.915-.692.001-4.475.403-5.548"
            clip-rule="evenodd"
          />
          <path
            fill="#FF6575"
            fill-rule="evenodd"
            d="M280.181 46.222 216.522 66.77a33.959 33.959 0 0 0 3.041 6.961c.155.268.322.543.582.711.474.307 1.097.172 1.643.028a145.137 145.137 0 0 1 34.405-4.712c3.905-.062 7.927.012 11.558-1.422 5.435-2.147 9.068-7.302 11.666-12.53 1.39-2.8 2.491-6.428.48-8.82"
            clip-rule="evenodd"
          />
          <path
            fill="#948CDC"
            fill-rule="evenodd"
            d="M225.093 162.407c-.128-17.342 5.13-30.829 15.517-40.094.267 14.299-5.394 28.617-15.37 38.877"
            clip-rule="evenodd"
          />
          <path
            fill="#4847BC"
            fill-rule="evenodd"
            d="M216.838 122.342c9.832 23.994 13.495 50.48 10.544 76.236-.346 3.028-.923 6.318-3.216 8.329-1.676 1.47-3.97 1.974-6.162 2.384a177.913 177.913 0 0 1-54.205 1.723c-1.42-.174-2.858-.37-4.172-.934-3.25-1.393-8.963-5.448-6.527-8.01 8.613-9.063-5.375-23.619-3.847-35.591 1.528-11.974 5.226-23.556 9.213-34.95.727-2.078 2.102-8.323 2.942-10.359.4-.968 9.572-1.747 10.957 1.368 5.297 11.923 12.009 31.266 17.307 43.189 3.978-12.626 9.018-31.287 12.995-43.914 1.846-1.774 10.897-2.42 13.347-1.264"
            clip-rule="evenodd"
          />
          <path
            fill="#211E53"
            fill-rule="evenodd"
            d="M155.56 171.937c7.648 2.11 12.332 10.365 19.933 12.639 6.369 1.905 13.163-.857 18.93-4.161 10.051-5.758 19.068-13.34 29.656-18.044.449-.199.96-.396 1.419-.219.598.231.799.96.909 1.59 1.322 7.521 1.09 15.216.854 22.847-.19 6.125-.426 12.449-3.047 17.99-.426.9-.943 1.807-1.779 2.352-.704.461-1.563.614-2.393.757-20.177 3.466-40.892 6.93-60.985 3.005-.753-.147-1.551-.329-2.088-.878-.982-1.001-5.554-5.183-4.432-6.025.539-.406-.18-1.185.563-1.72 4.425-3.186 4.236-7.926-1.938-24.537-1.397-3.758 4.398-5.596 4.398-5.596Z"
            clip-rule="evenodd"
          />
          <path
            fill="#948CDC"
            fill-rule="evenodd"
            d="M99.505 104.026c4.84-4.999 13.267-8.058 16.895-13.995-1.822-2.713-7.786-7.902-9.61-10.615-.512-.762-1.084-1.572-1.954-1.868-.926-.315-1.934.037-2.848.386-2.663 1.016-5.37 2.057-7.621 3.803-2.252 1.747-4.023 4.335-4.094 7.18-.075 3.001-.981 3.34.999 5.599 1.98 2.258 4.789 5.733 9.519 11.472"
            clip-rule="evenodd"
          />
          <path
            fill="#98A2EE"
            fill-rule="evenodd"
            d="M201.857 124.436c-3.603 4.003-8.858 6.475-14.245 6.699-5.385.224-10.829-1.803-14.752-5.494a1352.742 1352.742 0 0 0 7.453 25.234c1.808 5.912 3.686 11.877 6.875 17.177.703 1.169 2.029 2.459 3.217 1.788.532-.301.798-.907 1.023-1.474 5.257-13.18 7.645-27.294 10-41.284"
            clip-rule="evenodd"
          />

          <path
            fill="#FF6575"
            fill-rule="evenodd"
            d="M172.227 104.369a41.938 41.938 0 0 1 1.173 19.848c-.081.438-.166.897-.03 1.321.16.496.593.846 1.014 1.154 7.023 5.128 16.883 6.079 24.758 2.385 1.041-.488 2.152-1.185 2.382-2.31.133-.647-.059-1.312-.25-1.944l-5.559-18.458c-.184-.612-.385-1.252-.839-1.704-.577-.575-1.443-.729-2.252-.839a64.19 64.19 0 0 0-13.027-.432c-1.821.124-3.838.431-4.978 1.856"
            clip-rule="evenodd"
          />
          <path
            fill="#948CDC"
            fill-rule="evenodd"
            d="M172.169 124.366c2.511 3.496 5.979 6.335 9.985 7.918 4.007 1.583 8.548 1.874 12.669.619 3.032-.924 4.675-2.02 6.132-4.831.385-.743 1.321-3.873.902-4.598-.748-1.295-2.128.685-3.494 1.294-7.555 3.366-16.397 3.75-24.216 1.05"
            clip-rule="evenodd"
          />
          <path
            fill="#D53A79"
            fill-rule="evenodd"
            d="M178.053 121.142c1.838 1.621 4.478 2.162 6.894 1.751 2.417-.412 4.622-1.696 6.452-3.326 2.924-2.607 5.024-6.245 5.338-10.147.053-.675.051-1.378-.218-2-.43-.999-1.483-1.614-2.551-1.819-1.069-.205-2.169-.065-3.25.057a92.797 92.797 0 0 1-16.073.402c-.592-.037-1.342.016-1.552.569-.091.24-.013.503.009.759.959 10.6 4.951 13.754 4.951 13.754Z"
            clip-rule="evenodd"
          />
          <path
            fill="#FF6575"
            fill-rule="evenodd"
            d="M162.659 62.908c-2.819 15.407.739 31.879 9.666 44.756 1.902 2.742 4.086 5.372 6.921 7.133 2.835 1.76 6.423 2.555 9.576 1.458 3.1-1.078 5.333-3.76 7.231-6.434 8.178-11.527 13.247-25.948 10.734-39.85-.914-5.063-2.849-10.003-6.058-14.026-3.209-4.023-7.754-7.078-12.818-8.02-3.8-.709-7.744-.225-11.467.816-2.351.657-4.694 1.569-6.529 3.177-2.663 2.336-3.949 5.867-4.636 9.339-.687 3.47-.882 7.043-1.871 10.441"
            clip-rule="evenodd"
          />
          <path
            fill="#000"
            fill-rule="evenodd"
            d="M161.872 87.422c2.405-4.097 5.758-7.538 8.797-11.19a89.326 89.326 0 0 0 13.568-22.265 86.86 86.86 0 0 0 9.747 6.918c2.981 1.82 6.13 3.509 8.455 6.113 3.751 4.203 4.843 10.206 8.315 14.643.496-4.035-.238-8.125-1.307-12.047a71.332 71.332 0 0 0-6.643-16.205c-1.697-3.018-3.73-6.024-6.747-7.727-1.673-.944-3.56-1.435-5.436-1.849a80.874 80.874 0 0 0-9.011-1.46c-3.987-.416-8.294-.46-11.659 1.713-1.617 1.044-2.892 2.53-4.142 3.993-1.786 2.09-3.589 4.206-4.826 6.66-1.181 2.342-1.809 4.921-2.282 7.5a69.587 69.587 0 0 0-1.122 13.968c.037 1.76.147 3.544.735 5.202.588 1.657 1.719 3.194 3.325 3.914"
            clip-rule="evenodd"
          />
        </g>
        <path
          fill="#666766"
          fill-rule="evenodd"
          d="M300.253 215.638c0 2.067-64.233 3.743-143.468 3.743-79.235 0-143.469-1.676-143.469-3.743s64.234-3.744 143.469-3.744 143.468-1.004 143.468 3.744Z"
          clip-rule="evenodd"
          opacity=".183"
        />
        <path
          fill="#211E53"
          fill-rule="evenodd"
          d="M171.644 210.682 49.829 202.55a3.293 3.293 0 0 0-3.498 2.942l-.848 8.132a3.293 3.293 0 0 0 3.278 3.632h122.663a3.293 3.293 0 0 0 3.296-3.291 3.293 3.293 0 0 0-3.076-3.283Z"
          clip-rule="evenodd"
        />
        <path
          fill="#4847BC"
          fill-rule="evenodd"
          d="M122.322 217.256H35.583a5.23 5.23 0 0 1-5.097-4.056L17.35 155.942c-.75-3.27 1.738-6.389 5.098-6.389h86.739a5.228 5.228 0 0 1 5.097 4.056l13.136 57.258c.751 3.271-1.738 6.389-5.098 6.389Z"
          clip-rule="evenodd"
        />
        <path
          fill="#FF6575"
          fill-rule="evenodd"
          d="M72.61 181.32c1.522 4.668.356 9.233-2.605 10.197-2.96.962-6.595-2.043-8.118-6.712-1.522-4.668-.355-9.233 2.605-10.196 2.962-.962 6.597 2.042 8.118 6.711Z"
          clip-rule="evenodd"
        />
        <g :class="$style.visualCoffee">
          <path
            fill="#00B7AA"
            fill-rule="evenodd"
            d="M254.041 221.324c0 1.032-10.446 3.418-23.334 3.418-12.886 0-23.333-2.386-23.333-3.418s10.447-1.868 23.333-1.868c12.888 0 23.334.836 23.334 1.868Zm-37.02-5.311c-10.145 0-11.661-6.213-11.661-9.917 0-8.888 5.325-9.916 9.33-9.916v4.021c-3.203 0-5.303.41-5.303 5.895 0 2.745.869 5.896 7.634 5.896v4.021Z"
            clip-rule="evenodd"
          />

          <path
            fill="#0DCDA6"
            fill-rule="evenodd"
            d="M234.646 221.517h-6.403c-6.175 0-12.732-4.998-12.732-11.165l-1.553-14.279c0-2.275.296-4.12 2.575-4.12h29.823c2.279 0 2.574 1.845 2.574 4.12l-1.552 14.279c0 6.167-6.557 11.165-12.732 11.165Z"
            clip-rule="evenodd"
          />
        </g>

        <path
          :class="$style.visualSpeech"
          fill="#F5F3F9"
          fill-rule="evenodd"
          d="M77.916 110.677c13.861-5.591 25.469-16.658 31.3-31.675 11.198-28.84-3.136-61.287-32.017-72.47C48.317-4.653 15.826 9.662 4.628 38.504c-11.2 28.842 3.136 61.288 32.017 72.471a55.89 55.89 0 0 0 28.5 3.178L77.86 131.79l.055-21.113Z"
          clip-rule="evenodd"
        />
        <path
          fill="#E5F4F2"
          fill-rule="evenodd"
          d="m18.177 159.545 66.196 57.711-47.763.357a5.852 5.852 0 0 1-5.74-4.498l-12.693-53.57Z"
          clip-rule="evenodd"
          opacity=".202"
        />
        <path
          :class="$style.visualCheck"
          fill="#0DCDA6"
          fill-rule="evenodd"
          d="m68.775 52.215-11.99 15.75A2.624 2.624 0 0 1 54.712 69h-.018a2.629 2.629 0 0 1-2.066-1.008l-6.384-8.155a2.623 2.623 0 0 1 .449-3.683 2.622 2.622 0 0 1 3.685.449l4.287 5.475 9.933-13.043a2.623 2.623 0 1 1 4.176 3.179M57.501 32.252c-14.498 0-26.25 11.752-26.25 26.25 0 14.495 11.752 26.25 26.25 26.25s26.25-11.755 26.25-26.25c0-14.498-11.752-26.25-26.25-26.25"
          clip-rule="evenodd"
        />
      </svg>

      {{ $t('dashboard.schedule.empty') }}
      <BaseButton
        v-if="hasFeatureFlag('module-appointments')"
        color="inverted"
        :routerLink="{ name: 'create-appointment' }"
        v-test="'create-appointment'"
      >
        {{ $t('global.actions.create_appointment') }}
      </BaseButton>
    </div>
    <BaseVisualBanner
      v-if="
        !$apollo.loading &&
        showTreatwell &&
        !hasDashboard &&
        !isTreatwellUser &&
        shouldShowTWBanner
      "
      :heading="treatwellHeader"
      :variant="$screen === 's' ? 'small' : 'dark'"
      icon="treatwell"
      :imagePath="treatwellImage"
      mt
      :primaryAction="$t('treatwell.landing.button_start')"
      @primaryAction="openTreatwellModal"
      v-test="'treatwell-empty-schedule-banner'"
    />
  </div>
</template>

<script lang="ts">
import ScheduleItem from './ScheduleItem.vue';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useTreatwellStore } from '@/stores/treatwell';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ScheduleItem
  },
  inject: ['mixpanel'],
  props: {
    selectedResourceId: {
      type: Number
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { showTreatwell, hasDashboard } = useTreatwellStore();
    const { isTreatwellUser } = useCompanyStore();

    return {
      showTreatwell,
      hasDashboard,
      isTreatwellUser
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useTreatwellStore, ['showTreatwellOfferBanner']),
    treatwellImage(): string {
      switch (this.company.companyType) {
        case 'BARBERSHOP':
          return '/img/company-types/barbershop.svg';
        case 'NAIL_STUDIO':
        case 'MANI_PEDICURE':
          return '/img/company-types/nail_studio.svg';
        default:
          return '/img/treatwell/monitor.svg';
      }
    },
    treatwellHeader(): string {
      switch (this.company.companyType) {
        case 'BARBERSHOP':
          return this.$t(
            'treatwell.landing.salon_specific.barbershop.heading_visibility'
          );
        case 'NAIL_STUDIO':
        case 'MANI_PEDICURE':
          return this.$t(
            'treatwell.landing.salon_specific.nail_studio.heading_visibility'
          );
        default:
          return this.$t('treatwell.landing.heading_visibility');
      }
    },
    shouldShowTWBanner(): boolean {
      if (this.showTreatwellOfferBanner) {
        return false;
      }
      switch (this.company.companyType) {
        case 'BARBERSHOP':
        case 'NAIL_STUDIO':
        case 'MANI_PEDICURE':
          return !this.events || this.events.length < 5;
        default:
          return !this.events || !this.events.length;
      }
    }
  },
  methods: {
    onEmptyScheduleClick() {
      this.$router.push({ name: 'create-appointment' });
    },
    openTreatwellModal() {
      this.mixpanel.track('SalonizedDashboard-TreatwellPromo');
      this.$router.push({ name: 'treatwell-landing' });
    }
  },
  apollo: {
    events: {
      query: gql`
        query getEvents($resourceId: Int, $locationId: ID) {
          events(resourceId: $resourceId, locationId: $locationId) {
            ... on Appointment {
              id
              createdAt
              startAt
              endAt
              serviceNames
              online
              parts {
                resources {
                  id
                }
              }
              customer {
                fullName
              }
            }
            ... on Absence {
              allDay
              id
              startAt
              endAt
              name
              resourceId
              chore
              calendarAbsence {
                extendedProps {
                  imported
                }
              }
            }
          }
        }
      `,
      pollInterval: 5 * 60 * 1000,
      variables() {
        return {
          resourceId: this.selectedResourceId,
          locationId: this.locationId
        };
      },
      skip() {
        return !this.isVisible;
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  &.isLoading {
    min-height: 64px;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing;
  padding: $spacing * 2 $spacing;
}

.visual {
  width: 240px;
  margin-bottom: $spacing;
}

.visualSpeech {
  transform-origin: 40% 90%;
  animation: speech 0.5s 0s $easeOutExpo 1 both;
}

.visualCheck {
  animation: check 0.5s 0.2s $easeOutBack 1 both;
  transform-origin: 20% 25%;
}

@keyframes speech {
  from {
    transform: scale(0.7);
  }
}

@keyframes check {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
}
</style>
