<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'page-signup'"
  >
    <div :class="$style.header">
      <BaseHeading
        :size="$screen === 's' ? 'l' : 'xl'"
        mb
        center
        v-test="'signup-heading'"
      >
        {{ treatwellSignup ? $t('signup.heading_tw') : $t('signup.heading') }}
      </BaseHeading>
      <BaseText :mb="2" center>
        {{ $t('signup.description') }}
      </BaseText>
    </div>
    <BaseForm @submit="signup">
      <BaseInput
        v-model="name"
        :label="$t('signup.name')"
        name="name"
        required
        :minLength="2"
        mb
        v-test="'signup-name'"
      />
      <BaseInput
        v-model="companyName"
        :label="$t('signup.company_name')"
        required
        :minLength="2"
        mb
        v-test="'signup-company'"
      />
      <BaseInput
        v-model="email"
        :label="$t('signup.email')"
        type="email"
        name="email"
        required
        mb
        :error="emailErrorString"
        v-test="'signup-email'"
      />
      <BaseInput
        v-if="showPhone"
        v-model="phone"
        :label="$t('signup.phone')"
        type="tel"
        :regex="COMPANY_PHONE_VALIDATION_REGEX"
        name="phone"
        mb
        v-test="'signup-phone'"
      />
      <BaseInput
        v-model="password"
        :label="$t('signup.password')"
        type="password"
        name="password"
        required
        newPassword
        mb
        v-test="'signup-password'"
      />

      <BaseText center mb>
        <span v-html="termsConditionsText" />
      </BaseText>
      <BaseButton
        :loading="isLoading"
        size="l"
        fullWidth
        submitForm
        v-test="'signup-submit'"
      >
        {{ $t('signup.button') }}
      </BaseButton>
    </BaseForm>
    <div :class="$style.nav">
      <BaseText inline> {{ $t('signup.login_1') }}&nbsp; </BaseText>
      <BaseText :routerLink="{ name: 'login' }" bold v-test="'btn-login'">
        {{ $t('signup.login_2') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import { useSessionStore } from '@/stores/session';
import $axios from '@/axios';
import unleash from '@/unleash';
import { logValidationError } from '@/helpers/datadog';
import { usePageLayoutStore } from '@/stores/page-layout';
import {
  sendNativeAppMessage,
  NativeAppMessage
} from '@/helpers/native-app-bridge';

import { defineComponent } from 'vue';
import { COMPANY_PHONE_VALIDATION_REGEX } from '@/helpers/regex';

export default defineComponent({
  inject: ['mixpanel'],
  setup() {
    const { treatwellSignup } = useCompanyStore();
    const utmAttributes = inject('utmAttributes');

    return {
      treatwellSignup,
      utmAttributes
    };
  },
  data() {
    return {
      name: '',
      companyName: '',
      email: '',
      password: '',
      newsletter: false,
      isLoading: false,
      emailErrorString: null,
      phoneNumberValid: false,
      countryCode: '',
      phone: '',
      COMPANY_PHONE_VALIDATION_REGEX
    };
  },
  watch: {
    email() {
      this.emailErrorString = null;
    }
  },
  computed: {
    termsConditionsText() {
      return this.$t('signup.terms_conditions_1', {
        terms: `<a href="${this.$t('signup.terms_conditions_url')}" target="_blank" class="underline">${this.$t('signup.terms_conditions_2')}</a>`
      });
    },
    referrerOptions() {
      const choices = [
        'search_engine',
        'social_media',
        'trade_show',
        'online_ad',
        'review_site',
        'online_community',
        'sales_rep',
        'word_of_mouth',
        'other'
      ];

      return choices.map((choice) => ({
        label: this.$t(`signup.referrer_options.${choice}`),
        value: choice
      }));
    },
    showPhone() {
      const { treatwellSignup } = useCompanyStore();
      return (
        ['nl', 'lu', 'be', 'de', 'lv'].includes(this.countryCode) ||
        treatwellSignup
      );
    }
  },
  methods: {
    parseEmailError(error: string) {
      if (error.includes('is not available')) {
        this.emailErrorString = this.$t('signup.errors.email_in_use');
      } else {
        this.emailErrorString = this.$t('global.validations.invalid', {
          value: this.$t('global.email_address')
        });
      }
    },

    signup() {
      this.isLoading = true;

      const { treatwellSignup } = useCompanyStore();

      const signupAttributes = {
        companyName: this.companyName,
        email: this.email,
        fullName: this.name,
        password: this.password,
        updateNotifications: this.newsletter,
        locale: this.$route.params.locale,
        ...(this.phone.length > 0
          ? { phone: this.phone.replaceAll(' ', '') }
          : null),
        treatwellSales: treatwellSignup
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation signup($input: SignupInput!) {
              signup(input: $input) {
                company {
                  id
                }
                error
              }
            }
          `,
          variables: {
            input: {
              signupAttributes,
              utmAttributes: this.utmAttributes
            }
          }
        })
        .then(({ data: { signup } }) => {
          if (signup.error?.email) {
            this.parseEmailError(signup.error.email[0]);
            this.isLoading = false;
            return;
          }

          if (signup.error) {
            logValidationError('signup', signup.error, signupAttributes);
          }

          sendNativeAppMessage({
            message: NativeAppMessage.UserLogin
          });

          const { fetchAllData } = useSessionStore();
          fetchAllData().then(() => {
            const { locationId } = useLocationsStore();
            const { companyId } = useCompanyStore();

            this.$router.push({
              name: 'calendar',
              params: {
                companyId,
                locationId
              },
              query: {
                welcome: true
              }
            });

            const pageLayoutStore = usePageLayoutStore();
            pageLayoutStore.isNavExpanded = true;
          });
        })
        .finally(() => {
          this.mixpanel.track('Signup form submitted', {
            howDidYouHearAboutUs: unleash.isEnabled('HowDidYouHearAboutUs')
          });
          this.isLoading = false;
        });
    }
  },
  mounted() {
    const { startUnleash } = useSessionStore();

    $axios
      .get('/sessions/visitor_locale')
      .then(({ data: { country_code: countryCode } }) => {
        this.countryCode = countryCode;
      });

    startUnleash();
    this.$nextTick(() => {
      this.mixpanel.track('Signup Page Viewed', {
        howDidYouHearAboutUs: unleash.isEnabled('HowDidYouHearAboutUs')
      });
    });
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.prefixBase {
  display: flex;
  width: 100%;
  align-items: start;
}

.prefixStyle {
  width: 10%;
  background-color: $grey-light;
  height: 42px;
  border: $color-border 1px solid;
  border-radius: $radius;
  margin-bottom: $spacing;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing * 0.2;
  cursor: not-allowed;
}

.phone {
  width: 100%;
}

.nav {
  display: flex;
  justify-content: center;
  padding-top: $spacing * 2;
}
</style>
