import gql from 'graphql-tag';

export const GET_CONTRACT_TEMPLATE = gql`
  query getContractTemplate($type: String, $id: ID) {
    contractTemplate(type: $type, id: $id) {
      id
      content
      downloadUrl
    }
  }
`;
export const GET_CURRENT_INTEGRATION_REQUEST = gql`
  query getTreatwellCurrentIntegrationRequest {
    treatwellCurrentIntegrationRequest {
      accountHolderName
      accountNumber
      bankCode
      bankName
      contractType
      contractSigned
    }
  }
`;

export const GET_INTEGRATION_REQUEST_DESCRIPTION = gql`
  query getTreatwellCurrentIntegrationRequest {
    treatwellCurrentIntegrationRequest {
      changeRequest {
        descriptionAtmosphere
        descriptionBrandsProducts
        descriptionExperience
        descriptionExtras
        descriptionSpecialities
        descriptionTransports
      }
    }
  }
`;

export const TREATWELL_INTEGRATION_REQUEST_UPDATE_BANKING_DETAILS = gql`
  mutation treatwellIntegrationRequestUpdateBankingDetails(
    $input: IntegrationRequestUpdateBankingDetailsInput!
  ) {
    treatwellIntegrationRequestUpdateBankingDetails(input: $input) {
      treatwellIntegrationRequest {
        id
      }
      errors
    }
  }
`;

export const GET_TREATWELL_VENUE = gql`
  query getTreatwellVenue {
    treatwellVenue {
      bankAccountHolder
      bankAccountNumber
      bankCode
      bankName
      id
      url
      treatwellRosterDays {
        day
        openingTime
        closingTime
      }
    }
  }
`;

export const TREATWELL_INTEGRATION_REQUEST_ACCEPT_CONTRACT = gql`
  mutation treatwellIntegrationRequestAcceptContract(
    $input: IntegrationRequestAcceptContractInput!
  ) {
    treatwellIntegrationRequestAcceptContract(input: $input) {
      treatwellIntegrationRequest {
        contractSigned
      }
    }
  }
`;

export const TREATWELL_INTEGRATION_REQUEST_UPDATE_CONTRACT = gql`
  mutation treatwellIntegrationRequestUpdateContract(
    $input: IntegrationRequestUpdateContractInput!
  ) {
    treatwellIntegrationRequestUpdateContract(input: $input) {
      treatwellIntegrationRequest {
        contractSigned
      }
    }
  }
`;

export const UPDATE_TREATWELL_VENUE = gql`
  mutation updateTreatwellVenue($input: UpdateTreatwellVenueInput!) {
    updateTreatwellVenue(input: $input) {
      treatwellVenue {
        id
      }
      errors
    }
  }
`;

export const GET_FEEDBACK_COUNT = gql`
  query getFeedbackCount($locationId: ID, $dataScope: DataScope) {
    feedbackCount(locationId: $locationId, dataScope: $dataScope) {
      total
    }
  }
`;

export const GET_TREATWELL_DASHBOARD_STATISTICS = gql`
  query getTreatwellDashboardStatistics($range: DateRange) {
    treatwellDashboardStatistics(range: $range) {
      appointmentCount
      grossRevenue
    }
  }
`;

export const UPDATE_TREATWELL_ROSTER = gql`
  mutation updateTreatwellRoster($input: UpdateTreatwellRosterInput!) {
    updateTreatwellRoster(input: $input) {
      treatwellVenue {
        id
      }
      errors
    }
  }
`;

export const UPDATE_TREATWELL_INTENT = gql`
  mutation updateTreatwellIntent($input: UpdateTreatwellIntentInput!) {
    updateTreatwellIntent(input: $input) {
      clientMutationId
    }
  }
`;

export const GET_LAST_MINUTE_DISCOUNTS = gql`
  query getLastMinuteDiscounts {
    lastMinuteDiscounts {
      enabled
      discountPercentage
      discountWindow
    }
  }
`;

export const UPDATE_LAST_MINUTE_DISCOUNTS = gql`
  mutation updateLastMinuteDiscounts($input: UpdateLastMinuteDiscountsInput!) {
    updateLastMinuteDiscounts(input: $input) {
      lastMinuteDiscounts {
        enabled
        discountPercentage
        discountWindow
      }
    }
  }
`;

export const TREATWELL_INTEGRATION_REQUEST_REJECT = gql`
  mutation treatwellIntegrationRequestReject(
    $input: IntegrationRequestRejectInput!
  ) {
    treatwellIntegrationRequestReject(input: $input) {
      treatwellIntegrationRequest {
        id
      }
    }
  }
`;
