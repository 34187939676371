<template>
  <div class="mr-1">
    <BaseButton
      color="inverted"
      :loading="disputeCheckoutSessionLoading"
      @click="payInvoice"
      v-test="`invoices-card-invoice-${stripeInvoice.id}-pay-action`"
    >
      {{ $t('global.actions.pay') }}
    </BaseButton>
    <BaseModal v-if="showModal" small @close="showModal = false">
      <EmptyPageContent
        imageName="training-employee"
        :text="{
          title: $t('ios.subscription_blocked.heading'),
          description: $t('ios.subscription_blocked.description')
        }"
      />
    </BaseModal>
  </div>
</template>

<script lang="ts" setup>
import { useStripe } from '@/helpers/stripe';
import { InvoiceStatus, type StripeInvoice } from '@/types';
import { useNativeAppStore } from '@/stores/native-app';
import EmptyPageContent from '@/components/EmptyPageContent.vue';

const props = defineProps<{
  stripeInvoice: StripeInvoice;
}>();

const { isNativeAppIOS } = useNativeAppStore();
const showModal = ref(false);

const { goToDisputeCheckoutSession, disputeCheckoutSessionLoading } =
  useStripe();

const payInvoice = () => {
  if (isNativeAppIOS) {
    showModal.value = true;
    return;
  }

  if (props.stripeInvoice.realStatus === InvoiceStatus.Failed) {
    goToDisputeCheckoutSession(props.stripeInvoice.id);
    return;
  }

  if (props.stripeInvoice.hostedInvoiceUrl) {
    window.location.href = props.stripeInvoice?.hostedInvoiceUrl;
  }
};
</script>
