<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm',
        [$style.largeScreen]: $screen === 'l'
      }
    ]"
  >
    <router-view />
    <BaseSpinner v-if="loading" />
    <div v-else>
      <BaseAlert
        v-if="!contactEmailVerified"
        :text="t('email_templates.verification_alert.text')"
        icon="alert"
        color="warning"
        :mb="2"
        :primaryAction="t('email_templates.verification_alert.action')"
        @primaryAction="verifyContactEmail"
        v-test="'contact-email-verification-alert'"
      />
      <div :class="$style.templates">
        <div v-for="(group, index) in groupedTemplates" :key="index">
          <div :class="$style.templateHeading">
            <BaseHeading mb>
              {{ $t(`email_templates.categories.${group.name.toLowerCase()}`) }}
            </BaseHeading>
          </div>
          <div
            v-for="(item, itemIndex) in group.items"
            :key="itemIndex"
            @click="!contactEmailVerified ? showVerificationModal() : null"
          >
            <component
              :is="!contactEmailVerified ? 'span' : 'router-link'"
              :to="{
                name: 'message-template',
                params: {
                  id: item.id
                }
              }"
              v-test="'company-email-template'"
            >
              <TemplateLink :heading="item.heading" />
            </component>
          </div>
        </div>
      </div>
    </div>
    <Banner
      v-if="!loading && !isNativeAppIOS"
      v-test="'email-templates-banner'"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'EmailTemplates'
};
</script>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { useTemplateData } from './data';
import gql from 'graphql-tag';
import { groupBy } from '@/helpers/formatting';
import TemplateLink from '../TemplateLink.vue';
import Banner from './Banner.vue';
import { useContactEmailVerification } from '@/modules/marketing/useContactEmailVerification';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useNativeAppStore } from '@/stores/native-app';

const { templates } = useTemplateData();
const { t } = useI18n();
const { contactEmailVerified, verifyContactEmail } =
  useContactEmailVerification();

const { isNativeAppIOS } = useNativeAppStore();

const showVerificationModal = () => {
  modal('confirmation', {
    type: 'sendEmail',
    subMessage: t('email_templates.verification_modal.sub_message'),
    message: t('email_templates.verification_modal.message')
  }).then(() => {
    verifyContactEmail();
  });
};

const { result, loading } = useQuery(gql`
  query getCompanyEmailTemplates {
    companyEmailTemplates {
      id
    }
  }
`);

const fetchedTemplates = computed(
  () => result.value?.companyEmailTemplates || []
);

const groupedTemplates = computed(() => {
  const filteredTemplates = templates.filter(
    (template) =>
      !!fetchedTemplates.value.find((t: any) => t.id === template.id)
  );
  return groupBy(filteredTemplates, 'category').map((group, index) => ({
    ...group,
    slot: index + 1
  }));
});
</script>

<style lang="scss" module>
.base {
  &:not(.smallScreen) {
    padding: $spacing;
  }
}

.templates {
  .base:not(.smallScreen) & {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing * 2;
  }

  .base.smallScreen & {
    padding-top: $spacing;
  }

  & > * {
    .base.smallScreen & {
      &:not(:last-child) {
        margin-bottom: $spacing * 2;
      }
    }

    .base.mediumScreen & {
      width: calc(50% - #{$spacing * 3});
    }

    .base.largeScreen & {
      width: calc(25% - #{$spacing * 1.5});
    }
  }
}

.templateHeading {
  padding: 0 $spacing;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
