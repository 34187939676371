<template>
  <div v-if="loadingQuote" :class="$style.base">
    <BaseSpinner />
  </div>
  <div
    v-else
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div v-show="$screen !== 's' || isExpanded" :class="$style.main">
      <div
        v-show="$screen === 's'"
        :class="$style.closeOverlay"
        @click="isExpanded = false"
      >
        <BaseIcon name="chevron-down" size="l" />
      </div>
      <BaseHeading size="s" border mb>
        {{ $t('subscription.payment.your_subscription') }}
      </BaseHeading>
      <div v-if="priceSubscription" class="mt-1">
        <div :class="$style.summaryItem">
          <BaseText>
            {{
              $t(`subscription.types.${selectedPlan.toLowerCase()}.name_long`)
            }}
          </BaseText>
          <BaseText v-test="'subscription-summary-price'">
            {{ formatCurrency(priceSubscription) }}
          </BaseText>
        </div>
        <div v-if="priceResources" :class="$style.summaryItem">
          <div :class="$style.summaryItemLeft">
            <BaseText>
              {{ $t(`subscription.payment.employees`) }}
            </BaseText>
            <BaseIcon
              name="info"
              size="s"
              :ml="0.5"
              :tooltip="{
                text: $t(`subscription.payment.extra_employees_info`),
                touch: true
              }"
            />
          </div>
          <BaseText v-test="`subscription-summary-price-employee`">
            {{ formatCurrency(priceResources) }}
          </BaseText>
        </div>
        <div v-if="priceLocations" :class="$style.summaryItem">
          <div :class="$style.summaryItemLeft">
            <BaseText>
              {{ $t(`subscription.payment.locations`) }}
            </BaseText>
            <BaseIcon
              name="info"
              size="s"
              :ml="0.5"
              :tooltip="{
                text: $t(`subscription.payment.extra_locations_info`),
                touch: true
              }"
            />
          </div>
          <BaseText v-test="`subscription-summary-price-location`">
            {{ formatCurrency(priceLocations) }}
          </BaseText>
        </div>
        <div v-if="priceDiscount" :class="$style.summaryItem">
          <div :class="$style.summaryItemLeft">
            <BaseText color="success">
              {{ $t('subscription.payment.annual_discount') }}
            </BaseText>
          </div>
          <div />
        </div>
        <div v-if="priceTotal" :class="$style.summaryItem">
          <div :class="$style.summaryItemLeft">
            <BaseText bold>
              {{
                $t('subscription.payment.total_per_period', {
                  period: $t(
                    `global.items.${selectedPeriod === SubscriptionPeriod.Year ? 'year' : 'month'}`,
                    1
                  )
                })
              }}
            </BaseText>
            <BaseIcon
              name="info"
              size="s"
              :ml="0.5"
              :tooltip="{
                text: $t('subscription.payment.ex_vat'),
                touch: true
              }"
            />
          </div>
          <BaseText bold v-test="'subscription-summary-price-total'">
            {{ formatCurrency(priceTotal) }}
          </BaseText>
        </div>

        <div
          v-if="!canUpgradeSubscription && company.hasReferrer"
          :class="$style.summaryItem"
          v-test="'subscription-referral-discount-message'"
        >
          <BaseText color="success">
            {{ $t('subscription.payment.referral_discount') }}
          </BaseText>
          <div />
        </div>
        <transition name="pop">
          <div
            v-if="selectedTerminal && formData.plan !== 'appointments'"
            class="mt-2"
            v-test="'selected-terminal'"
          >
            <BaseHeading size="s" border mb>
              {{ $t('subscription.terminals.summary.pay_later') }}
            </BaseHeading>
            <div :class="$style.summaryItem">
              <BaseText>
                {{ selectedTerminal.name }}
              </BaseText>
              <div>
                <BaseText>
                  {{ formatCurrency(selectedTerminal.price) }}
                </BaseText>
                <BaseIcon
                  clickable
                  name="delete"
                  size="s"
                  color="primary"
                  :ml="0.5"
                  @click="selectedTerminalId = null"
                  v-test="'terminal-remove'"
                />
              </div>
            </div>
            <div :class="$style.summaryItem">
              <BaseText bold>
                {{ $t('subscription.terminals.summary.one_time_payment') }}
              </BaseText>
              <BaseText bold>
                {{ formatCurrency(selectedTerminal.price) }}
              </BaseText>
            </div>
          </div>
        </transition>
      </div>

      <BaseCard
        v-if="canUpgradeSubscription"
        gray
        :mt="2"
        v-test="'subscription-upgrade-charge-info'"
      >
        <BaseText>{{ $t('subscription.upgrade_charge') }}</BaseText>
      </BaseCard>
      <TerminalSelection
        v-else-if="!showTreatwell && showTerminalSection && !selectedTerminal"
        @select="selectedTerminalId = $event"
      />
    </div>
    <div
      v-show="$screen === 's' && !isExpanded"
      :class="$style.smallSummary"
      @click="isExpanded = true"
    >
      <div v-if="priceTotal" :class="$style.summaryItem">
        <BaseText bold>
          {{
            $t('subscription.payment.total_per_period', {
              period: $t(
                `global.items.${selectedPeriod === SubscriptionPeriod.Year ? 'year' : 'month'}`,
                1
              )
            })
          }}
        </BaseText>
        <div>
          <BaseText bold>
            {{ formatCurrency(priceTotal) }}
          </BaseText>
          <BaseIcon name="chevron-up" size="l" :ml="0.5" />
        </div>
      </div>
    </div>

    <div :class="$style.footer">
      <BaseCard
        v-if="showTreatwell"
        gray
        mb
        v-test="'subscription-treatwell-intention-card'"
      >
        <BaseText bold mb>
          {{ $t('subscription.treatwell_card.title') }}
          <img
            :class="$style.treatwellLogo"
            src="/img/treatwell/logo-small.svg"
          />
        </BaseText>
        <BaseText>
          {{ $t('subscription.treatwell_card.description') }}
        </BaseText>
      </BaseCard>
      <BaseButton
        size="l"
        fullWidth
        :loading="isLoading"
        @click="onSubmit"
        v-test="'subscription-submit'"
      >
        {{
          $t(
            canUpgradeSubscription
              ? 'subscription.payment.upgrade_button'
              : 'subscription.payment.subscription_button'
          )
        }}
      </BaseButton>
      <BaseText
        v-if="!canUpgradeSubscription"
        mt
        size="s"
        v-test="'subscription-terms-and-conditions'"
      >
        <span v-html="termsConditionsText" />
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
import { computed, ref, inject, watch } from 'vue';
import { SubscriptionPeriod, SubscriptionPrice } from '@/types';
import unleash from '@/unleash';
import { useI18n } from 'vue-i18n';
import { useStripeSubscription } from './stripe/actions';
import { useStripeStore } from './stripe/store';
import TerminalSelection from './TerminalSelection.vue';
import { terminals } from '@/modules/register/salonized-pay/terminals';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useSubscriptionStore } from './store';
import { useTreatwellStore } from '@/stores/treatwell';

const { t } = useI18n();
const { showTreatwell } = useTreatwellStore();

const isExpanded = ref(false);
const showTerminalSection = unleash.isEnabled('SubscriptionTerminal');
const isTreatwellSelected = ref(showTreatwell);
const mixpanel = inject<any>('mixpanel');

const { createStripeSubscription, upgradeStripeSubscription, isLoading } =
  useStripeSubscription();

const { selectedPeriod, selectedPlan, activeQuote, loadingQuote } =
  storeToRefs(useStripeStore());

const { formData } = useSubscriptionStore();

const { canUpgradeSubscription, company } = useCompanyStore();
const selectedTerminalId = ref<string | null>(null);
const selectedTerminal = computed(() =>
  selectedTerminalId.value
    ? terminals.find((terminal) => terminal.key === selectedTerminalId.value)
    : null
);

watch(selectedPeriod, () =>
  mixpanel.track(
    `Subscription flow - Frequency changed: ${selectedPeriod.value}`
  )
);

const termsConditionsText = computed(() =>
  t('signup.terms_conditions_1', {
    terms: `<a href="${t('signup.terms_conditions_url')}" target="_blank">${t('signup.terms_conditions_2')}</a>`
  })
);

const formatCurrency = (value: number) =>
  filters.currency(value, activeQuote.value?.lineItems?.[0].currency);

const priceSubscription = computed(
  () =>
    activeQuote.value?.lineItems?.find(
      (item) => item.itemType === SubscriptionPrice.Base
    )?.amountSubtotal
);
const priceResources = computed(
  () =>
    activeQuote.value?.lineItems?.find(
      (item) => item.itemType === SubscriptionPrice.Resources
    )?.amountSubtotal
);
const priceLocations = computed(
  () =>
    activeQuote.value?.lineItems?.find(
      (item) => item.itemType === SubscriptionPrice.Locations
    )?.amountSubtotal
);
const priceDiscount = computed(() =>
  activeQuote.value && priceSubscription.value
    ? (priceSubscription.value * activeQuote.value.percentageDiscount) / 100
    : 0
);
const priceTotal = computed(() => activeQuote.value?.amountSubtotal);

const onSubmit = () => {
  mixpanel.track('Subscription flow - Submit clicked', {
    terminalSelection: showTerminalSection,
    terminal: selectedTerminalId.value
  });
  if (canUpgradeSubscription) {
    upgradeStripeSubscription(selectedPlan.value, selectedPeriod.value);
  } else if (selectedTerminalId.value && formData.plan !== 'appointments') {
    const { mutate } = useMutation(gql`
      mutation createTerminalOrder($input: CreateTerminalOrderInput!) {
        createTerminalOrder(input: $input) {
          errors
        }
      }
    `);
    mutate({
      input: {
        terminalModel: selectedTerminalId.value
      }
    }).finally(() => {
      createStripeSubscription(
        selectedPlan.value,
        selectedPeriod.value,
        isTreatwellSelected.value
      );
    });
  } else {
    createStripeSubscription(
      selectedPlan.value,
      selectedPeriod.value,
      isTreatwellSelected.value
    );
  }
};
</script>

<style lang="scss" module>
.base {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  position: relative;
  overflow-y: auto;
}

.closeOverlay {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: $spacing;
}

.main,
.footer {
  .base.smallScreen & {
    padding: $spacing;
  }
  .base:not(.smallScreen) & {
    padding: $spacing * 1.5;
  }
}

.main {
  .base.smallScreen & {
    padding-bottom: 0;
  }
}

.smallSummary {
  padding: $spacing;
  margin-bottom: $spacing * -1;
}

.summaryItem {
  display: flex;

  & > *:last-child {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.summaryItemLeft {
  display: flex;
  align-items: center;
}

.treatwellLogo {
  position: relative;
  height: 12px;
  top: 0;
  left: 2px;
}
</style>
