<template>
  <div>
    <BaseAlert
      v-if="!contactEmailVerified"
      :text="t('email_templates.verification_alert.text')"
      icon="alert"
      color="warning"
      :mb="2"
      :primaryAction="t('email_templates.verification_alert.action')"
      @primaryAction="verifyContactEmail"
      v-test="'contact-email-verification-alert'"
    />
    <MessageBlock
      :title="$t('marketing.email.new_message.title')"
      :description="$t('marketing.email.new_message.description')"
      :buttonText="$t('global.actions.create')"
      mixpanelEvent="Create newsletter button clicked"
      buttonRoute="newsletter-template"
    />
    <TemplatesOverview />
    <AutomatedEmails v-test="'automated-emails'" />
    <MessageBlock
      :title="$t('marketing.email.messages_log.title')"
      :description="$t('marketing.email.messages_log.description')"
      :buttonText="$t('marketing.email.messages_log.route')"
      buttonRoute="messages"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import MessageBlock from './MessageBlock.vue';
import AutomatedEmails from './AutomatedEmails.vue';
import TemplatesOverview from './templates-overview/index.vue';

import { useContactEmailVerification } from '../useContactEmailVerification';

const { contactEmailVerified, verifyContactEmail } =
  useContactEmailVerification();

const { t } = useI18n();
</script>
