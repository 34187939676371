import gql from 'graphql-tag';

export const SEND_CONTACT_EMAIL_VERIFICATION_EMAIL = gql`
  mutation sendContactEmailVerificationEmail(
    $input: SendContactEmailVerificationEmailInput!
  ) {
    sendContactEmailVerificationEmail(input: $input) {
      verificationMailSent
      errors {
        attribute
        message
        type
      }
    }
  }
`;
