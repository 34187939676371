<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <div :class="$style.headerContainer">
      <PageWrap wide>
        <div :class="$style.header">
          <div :class="$style.heading">
            <BaseHeading size="xl">
              {{ $t('store.landing.header') }}
            </BaseHeading>
            <BaseText mt size="l">
              {{ $t('store.landing.content') }}
            </BaseText>
            <div
              v-if="!companySettings.store?.interestedAt"
              :class="$style.banner"
            >
              <div :class="$style.text">
                <BaseText size="l" color="inverted">
                  {{ $t('store.landing.register_interest_banner.header') }}
                </BaseText>
                <BaseText size="m" color="inverted">
                  {{ $t('store.landing.register_interest_banner.content') }}
                </BaseText>
              </div>
              <BaseButton
                color="inverted"
                icon="arrow-right"
                :loading="savingInterest"
                @click="updateInterest"
                v-intercom="'store-interested'"
                v-test="'store-interested-button'"
              >
                {{ $t('store.landing.register_interest_banner.button') }}
              </BaseButton>
            </div>
            <BaseTask
              v-else
              :heading="
                $t('store.landing.register_interest_banner.completed.heading')
              "
              :text="
                $t('store.landing.register_interest_banner.completed.text')
              "
              state="completed"
              mt
              v-test="'store-task-completed'"
            />
          </div>
          <img src="/img/store/header.svg" :class="$style.image" />
        </div>
      </PageWrap>
      <div :class="$style.firstWave" />
    </div>
    <PageWrap wide>
      <Products />
      <USPS />
    </PageWrap>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';

import { useCompanyStore } from '@/stores/company';

import PageWrap from '@/modules/admin/PageWrap.vue';
import Products from './Products.vue';
import USPS from './USPS.vue';

import dayjs from '@/dayjs';

const intercom = inject<any>('intercom');
const mixpanel = inject<any>('mixpanel');

const savingInterest = ref(false);
const { company, companySettings, updateCompany } = useCompanyStore();
const updateInterest = () => {
  if (!companySettings.store?.interestedAt) {
    savingInterest.value = true;

    const survey_id = company.language === 'nl' ? 45344261 : 45230075;
    intercom.startSurvey(survey_id);

    const interestedAt = dayjs().format();

    updateCompany({ settings: { store: { interestedAt } } }).then(() => {
      companySettings.store.interestedAt = interestedAt;
      savingInterest.value = false;
      mixpanel.track('Treatwell Store - Get started clicked', {
        cta: 'header'
      });
    });
  }
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
  width: 100%;
  background-color: white;
}

.headerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing;
  position: relative;

  .base:not(.smallScreen) & {
    height: 100%;
  }

  background-size: 100% 100%;
  background-position:
    0px 0px,
    0px 0px,
    0px 0px;
  background-image: linear-gradient(0deg, #dcdcf9d1 1%, #ffffff00 28%),
    radial-gradient(75% 75% at 88% -21%, #03cfa569 1%, #ffffff00 62%),
    radial-gradient(75% 75% at 50% 50%, #ffffffff 0%, #ffffffff 99%);
}

.header {
  display: flex;
  gap: $spacing;
  height: 70vh;
  justify-content: space-around;
  align-items: center;

  .mediumScreen & {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .smallScreen & {
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing * 12;
  }
}

.heading {
  max-width: 100%;
  .smallScreen & {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: $spacing;
  }

  .mediumScreen & {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: $spacing * 5;
  }
}

.image {
  max-width: 700px;
  width: 100%;
  height: auto;
  margin-left: $spacing * 2;

  .smallScreen & {
    margin-top: $spacing * 4;
    margin-left: 0px;
  }

  .mediumScreen & {
    margin-top: $spacing * 4;
    margin-left: 0px;
    padding-bottom: 100px;
  }
}

.firstWave {
  position: absolute;
  bottom: 0;
  background-image: url('/img/salonized-pay/rectangle2.svg');
  width: 100%;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner {
  margin: 2 * $spacing 0;
  padding: $spacing 1.5 * $spacing;
  gap: $spacing;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: $radius;
  background-color: $color-primary;

  .smallScreen & {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 0.25 * $spacing;
  align-items: start;
}
</style>
