<template>
  <RebookEmptyPage v-if="!enabled" />
  <div
    v-else
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <router-view />
    <div :class="$style.header">
      <Flex :mb="0.5">
        <BaseToggle v-model="enabled" v-test="'intro-toggle'" />
        <BaseHeading>{{
          $t('rebook_reminder.empty_state.disable')
        }}</BaseHeading>
      </Flex>
      <BaseText>
        {{ $t('marketing.email.automated_emails.rebook.receive_info') }}
      </BaseText>
    </div>
    <div :class="$style.inner">
      <div :class="$style.wrap">
        <div :class="$style.col">
          <RebookStats dashboard />
        </div>
        <div :class="$style.col">
          <BaseCard
            :heading="filters.capitalize($t('global.items.service', 2))"
            :mb="1.5"
          >
            <template #header>
              <BaseLabel color="success" v-test="'enabled-services-count'">{{
                $t('rebook_reminder.services_selected', {
                  amount: rebookEnabledServicesCount
                })
              }}</BaseLabel></template
            >
            <BaseText>
              {{ $t('rebook_reminder.description_services') }}
            </BaseText>
            <BaseButton
              mt
              fullWidth
              color="inverted"
              :routerLink="{
                name: 'rebook-reminder-services'
              }"
              @click="
                mixpanel.track('Rebook reminder dashboard - setup services')
              "
              v-test="'open-rebook-setup'"
            >
              {{ $t('rebook_reminder.modify_services') }}
            </BaseButton>
          </BaseCard>
          <BaseCard :heading="$t('global.email')">
            <template #header>
              <BaseLabel color="success" state="enabled"
            /></template>
            <BaseText>
              {{ $t('rebook_reminder.description_email') }}
            </BaseText>
            <BaseButton
              mt
              fullWidth
              color="inverted"
              :routerLink="{
                name: 'rebook-reminder-template'
              }"
              @click="clickTemplate"
              v-test="'edit-rebook-email'"
            >
              {{ $t('rebook_reminder.customize_email') }}
            </BaseButton>
          </BaseCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RebookEmptyPage from './RebookEmptyPage.vue';
import RebookStats from '../../insights/marketing-area/RebookStats.vue';

import router from '@/router';
import filters from '@/filters';

import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';
import { useStore } from 'vuex';
import { flash, modal } from '@/helpers/ui';
import { useServicesStore } from '@/stores/services';
import { storeToRefs } from 'pinia';
import { useContactEmailVerification } from '../../useContactEmailVerification';

const mixpanel = inject<any>('mixpanel');

const { updateCompany } = useCompanyStore();
const { company } = storeToRefs(useCompanyStore());
const store = useStore();
const { contactEmailVerified, verifyContactEmail } =
  useContactEmailVerification();
const { t } = useI18n();

const { rebookEnabledServicesCount } = storeToRefs(useServicesStore());

const enabled = computed({
  get() {
    return company.value.settings.bookings.rebookReminderEnabled;
  },
  set() {
    const input = {
      settings: {
        bookings: {
          rebookReminderEnabled: false
        }
      }
    };

    updateCompany(input).then(() => {
      store.commit('marketing/builder/SET_ENABLED', false);
      router.push({ name: 'rebook-reminder-dashboard' });
      flash(t('global.flash.rebook_reminder_disabled'));
    });
  }
});

const clickTemplate = () => {
  if (!contactEmailVerified) {
    modal('confirmation', {
      type: 'sendEmail',
      subMessage: t('email_templates.verification_modal.sub_message'),
      message: t('email_templates.verification_modal.message')
    }).then(() => {
      verifyContactEmail();
    });
  } else {
    mixpanel.track('Rebook reminder dashboard - edit email');
  }
};
</script>

<style lang="scss" module>
.header {
  margin: $spacing * -1;
  padding: $spacing;
  background-color: white;
  border-bottom: 1px solid $color-border;
}

.inner {
  background: $grey-light;
  width: calc(100% + #{$spacing * 2});
  padding: $spacing * 1.5;
  margin-top: $spacing;
  margin-left: -$spacing;
}

.wrap {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  gap: $spacing * 1.5;

  .base.smallScreen & {
    flex-direction: column;
  }
}

.col {
  .base:not(.smallScreen) & {
    width: 50%;
  }
}
</style>
