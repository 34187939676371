import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export const useRegisterBrowserStore = defineStore('register/browser', () => {
  const showMobileBrowser = ref(false);

  type Favorite = {
    label: string;
    id: number;
    medical: boolean;
    type: 'product' | 'service';
    price: number;
  };

  const favorites = ref<Favorite[]>([]);
  const loadingFavorites = ref(false);

  const fetchFavorites = () => {
    loadingFavorites.value = true;

    const { onResult } = useQuery(gql`
      query getRegisterItems {
        registerItems(favorite: true) {
          id
          type
          name
          price
          productId
          serviceId
          favorite
          medical
        }
      }
    `);

    onResult(({ data: { registerItems } }) => {
      favorites.value = registerItems.map((item: any) => ({
        label: item.name,
        id: item.productId ? item.productId : item.serviceId,
        medical: item.medical,
        type: item.type,
        price: item.price
      }));

      loadingFavorites.value = false;
    });
  };

  const { mutate: updateProduct } = useMutation(gql`
    mutation updateProducts($input: UpdateProductInput!) {
      updateProduct(input: $input) {
        product {
          favorite
        }
      }
    }
  `);

  const { mutate: markServiceVariationAsFavorite } = useMutation(gql`
    mutation markServiceVariationAsFavorite(
      $input: MarkServiceVariationAsFavoriteInput!
    ) {
      markServiceVariationAsFavorite(input: $input) {
        service {
          favorite
        }
      }
    }
  `);

  const addFavorite = (item: any) => {
    const { label, id, medical, type, price } = item;
    favorites.value.push({ label, id, medical, type, price });
    updateFavorite(item, true);
  };

  const removeFavorite = (item: Favorite) => {
    favorites.value = favorites.value.filter(
      (favorite) => favorite.id !== item.id
    );
    updateFavorite(item, false);
  };

  const updateFavorite = (item: Favorite, favorite: boolean) => {
    const { id, type } = item;

    if (type === 'service') {
      markServiceVariationAsFavorite({
        input: {
          serviceId: id,
          favorite
        }
      });
    } else if (type === 'product') {
      updateProduct({
        input: {
          id,
          favorite
        }
      });
    }
  };

  return {
    fetchFavorites,
    favorites,
    addFavorite,
    removeFavorite,
    loadingFavorites,
    showMobileBrowser
  };
});
