<template>
  <BaseModalLarge
    :heading="filters.capitalize($t('global.items.appointment', 1))"
    :label="labels"
    testId="appointment"
    autoHeight
    :actions="actions"
    :loading="$apollo.loading"
    :parentRoute="{
      name: parentRouteName
    }"
    :previewMode="previewModeActive"
    @action="onActionClick"
    @close="onClose"
  >
    <template #headerRight>
      <PreviewModeToggle />
    </template>
    <template #mobileTop>
      <BasicInfo v-if="smallScreen" :appointment="appointment" />
    </template>
    <router-view />
    <PendingAppointmentActions
      v-if="pendingConfirmation"
      :appointmentId="appointment.id"
      @confirmed="pendingConfirmation = false"
      v-test="'pending-appointment-actions'"
    />
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: smallScreen
        }
      ]"
    >
      <BasicInfo v-if="!smallScreen" :appointment="appointment" :mb="2" />
      <Services :appointment="appointment" :mb="2" />

      <BaseCard
        v-if="appointment && appointment.notes"
        :heading="filters.capitalize($t('global.items.note', 1))"
        :mb="2"
      >
        {{ appointment.notes }}
      </BaseCard>

      <Notifications
        v-if="appointment && appointment.customer"
        :customer="appointment.customer"
        :mb="2"
      />

      <Feedback
        v-if="appointment && appointment.feedback"
        :feedback="appointment.feedback"
        :mb="2"
      />

      <Reports
        v-if="
          appointment &&
          appointment.customer &&
          appointment.customer.appointmentReports &&
          appointment.customer.appointmentReports.length &&
          hasFeatureFlag('appointment-reports') &&
          !appointment.deleted &&
          !appointment.cancelled
        "
        :reports="appointment.customer.appointmentReports"
        :appointmentId="appointment.id"
        :mb="2"
        v-test="'reports'"
      />

      <div v-if="appointment && timelineItems && timelineItems.length">
        <BaseCard :heading="$t('global.history')">
          <Timeline :data="timelineItems" v-test="'timelineItems'" />
        </BaseCard>
      </div>
    </div>
    <template #aside>
      <div v-if="customer" :class="$style.customer">
        <BaseCard noPadding>
          <div :class="$style.customerInner">
            <CustomerDetails
              :customer="customer"
              :appointmentStartAt="appointment ? appointment.startAt : null"
              v-test="'appointment-customer'"
            />
            <CustomerMember :customerMember="appointment?.customerMember" ml />
          </div>
        </BaseCard>
      </div>
      <div v-else :class="$style.noCustomer">
        <BaseText bold mb v-test="'appointment-nocustomer'">
          {{ $t('appointment.no_customer') }}
        </BaseText>
        <CustomerSearch
          showCreate
          @select="addCustomer"
          v-test="'appointment-customer-search'"
        />
      </div>
      <LabelSelection
        v-if="appointment"
        :appointmentId="appointment.id"
        :labelId="
          appointment.calendarLabel ? appointment.calendarLabel.id : null
        "
        @select="onLabelSelect"
      />
    </template>
    <DeleteTWAppointmentModal
      v-if="showRefundModal"
      :appointment="appointment"
      :appointmentId="appointment.id"
      :appointmentStartAt="appointment.startAt"
      :amount="appointment.pricePaidThroughTreatwell"
      @onClose="closeRefundModal"
      @onSubmit="onDeleted"
    />
  </BaseModalLarge>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import dayjs from '@/dayjs';
import CustomerMember from '@/components/customer-details/CustomerMember.vue';
import Timeline from '@/components/Timeline.vue';
import LabelSelection from './labels/index.vue';
import Reports from './Reports.vue';
import Feedback from './Feedback.vue';
import Notifications from './Notifications.vue';
import PendingAppointmentActions from './PendingAppointmentActions.vue';
import gql from 'graphql-tag';
import { resourceFragment, customerFragment } from '@/graphql-fragments';
import CustomerDetails from '@/components/customer-details/index.vue';
import CustomerSearch from '@/components/CustomerSearch.vue';
import DeleteTWAppointmentModal from './DeleteTWAppointmentModal.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState, mapActions, storeToRefs } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import {
  deleteAppointment,
  updateAppointment
} from '@/modules/calendar/actions/appointments';
import { defineComponent } from 'vue';
import config from '@/config';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import PreviewModeToggle from '@/modules/calendar/PreviewModeToggle.vue';
import Services from './services/index.vue';
import BasicInfo from './BasicInfo.vue';
import { useOnboardingStore } from '@/stores/onboarding';
import eventBus from '@/event-bus';
import { useTreatwellStore } from '@/stores/treatwell';

export default defineComponent({
  components: {
    CustomerMember,
    Timeline,
    LabelSelection,
    Reports,
    Feedback,
    Notifications,
    CustomerDetails,
    CustomerSearch,
    DeleteTWAppointmentModal,
    PendingAppointmentActions,
    PreviewModeToggle,
    Services,
    BasicInfo
  },
  inject: ['mixpanel'],
  props: {
    parentRouteName: {
      type: String,
      required: true
    }
  },
  emits: ['deleted', 'updated'],
  setup() {
    const { previewLayoutActive } = storeToRefs(useCalendarPreviewStore());

    return {
      filters,
      previewModeActive: previewLayoutActive
    };
  },
  data() {
    return {
      timeline: [],
      originalStartAt: this.$route.query.date,
      showRefundModal: false
    };
  },
  apollo: {
    appointment: {
      query: gql`
        query getAppointment($id: Int!) {
          appointment(id: $id) {
            id
            startAt
            endAt
            cancelled
            deleted
            selectedResourceId
            locationId
            notes
            reschedulable
            draggable
            rrule
            endBuffer
            feedback {
              comment
              score
            }
            orders {
              credit
              draft
              id
              number
              paid
              total
              permissions {
                editOrderItems
              }
            }
            noShow
            pendingConfirmation
            calendarLabel {
              color
              id
              title
            }
            customer {
              ...customer
              appointmentReports(limit: 5, sortDirection: DESC) {
                id
                report
                createdAt
              }
            }
            customerMember {
              firstName
              lastName
            }
            discountCode {
              code
              discountPercentage
            }
            parts {
              id
              duration
              service {
                id
                name
              }
              resources {
                ...resource
              }
            }
            report {
              id
            }
            paidThroughTreatwell
            pricePaidThroughTreatwell
            price
            priceAdjustment {
              amount
              adjustmentType
            }
            treatwell
            treatwellOrder {
              reference
            }
          }
        }
        ${resourceFragment}
        ${customerFragment}
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.appointmentId)
        };
      },
      skip() {
        return !this.$route.params.appointmentId;
      },
      fetchPolicy: 'no-cache'
    },
    timelineItems: {
      query: gql`
        query getAppointment($id: Int!) {
          appointment(id: $id) {
            id
            timelineItems {
              meta
              key
              changes
              createdAt
              user {
                email
                fullName
                id
              }
            }
          }
        }
      `,
      update: (data) => data.appointment.timelineItems,
      variables() {
        return {
          id: parseInt(this.$route.params.appointmentId)
        };
      },
      skip() {
        return !this.$route.params.appointmentId;
      }
    }
  },
  watch: {
    '$route.name'(newValue, oldValue) {
      if (!this.appointment?.id || !this.appointment?.customer) {
        return;
      }

      const appointmentRoutes = ['appointment', 'customer-appointment'];
      const reportRoutes = [
        'appointment-report-new',
        'appointment-report-edit',
        'customer-appointment-report-new',
        'customer-appointment-report-edit'
      ];

      if (
        appointmentRoutes.includes(newValue) &&
        reportRoutes.includes(oldValue)
      ) {
        this.$apollo
          .query({
            query: gql`
              query getAppointment($id: Int!) {
                appointment(id: $id) {
                  customer {
                    appointmentReports(limit: 5, sortDirection: DESC) {
                      id
                      report
                      createdAt
                    }
                  }
                  customerMember {
                    firstName
                    lastName
                  }
                }
              }
            `,
            variables: {
              id: this.appointment.id
            }
          })
          .then(
            ({
              data: {
                appointment: {
                  customer: { appointmentReports }
                }
              }
            }) => {
              this.appointment.customer.appointmentReports = appointmentReports;
            }
          );
      }
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['multiLocation']),
    ...mapState(useLocationsStore, ['locations', 'locationById']),
    ...mapState(useTreatwellStore, ['treatwellStatus']),
    smallScreen() {
      return this.$screen === 's' || this.previewModeActive;
    },
    actions() {
      return this.isActive
        ? [
            ...this.appointmentOrders.map((order) => ({
              name: 'invoice',
              icon: 'pdf',
              href: `${config.backendUrl}/orders/${order.id}.pdf`,
              label: `${
                order.total < 0
                  ? this.filters.capitalize(
                      this.$t('global.items.credit_invoice', 1)
                    )
                  : this.filters.capitalize(this.$t('global.items.invoice', 1))
              } ${order.number}`
            })),
            {
              name: 'checkout',
              icon: 'shopping-cart',
              label: this.$t('global.actions.checkout'),
              hide:
                !this.hasFeatureFlag('module-register') || this.hasPaidInvoice,
              disabled: this.pendingConfirmation,
              disabledTooltip: this.$t('appointment.pending_action_disabled')
            },
            {
              name: 'reports',
              icon: 'clipboard',
              label: this.hasReport
                ? this.$t('global.actions.edit_report')
                : this.$t('appointment.write_report'),
              routerLink: this.hasReport
                ? `${this.appointment.id}/report/${this.appointment.report.id}/edit`
                : `${this.appointment.id}/report`,
              hide: !this.hasFeatureFlag('appointment-reports'),
              disabled: !this.customer && !this.hasReport,
              disabledTooltip: this.$t('appointment.disabled_reports_message')
            },
            {
              name: 'forms',
              icon: 'file',
              label: this.filters.capitalize(this.$t('global.items.form', 2)),
              routerLink: `${this.appointment.id}/forms`,
              disabled: !this.customer,
              disabledTooltip: this.$t('appointment.disabled_forms_message')
            },
            {
              name: 'edit',
              icon: 'edit',
              label: this.$t('global.actions.edit'),
              routerLink:
                this.$route.name === 'customer-appointment'
                  ? {
                      name: 'customer-edit-appointment',
                      params: { appointmentId: this.appointment.id }
                    }
                  : {
                      name: 'edit-appointment',
                      params: { id: this.appointment.id }
                    }
            },
            {
              name: 'move',
              icon: 'move',
              label: this.$t('global.actions.move'),
              hide: !this.appointment.reschedulable
            },
            {
              name: 'copy',
              icon: 'copy',
              label: this.$t('global.actions.copy'),
              disabled: this.appointment.treatwell || this.pendingConfirmation,
              disabledTooltip: this.pendingConfirmation
                ? this.$t('appointment.pending_action_disabled')
                : this.$t('appointment.disabled_copy_message')
            },
            {
              name: 'no-show',
              icon: 'close-square',
              disabled:
                (this.appointment.noShow && this.appointment.treatwell) ||
                this.pendingConfirmation,
              disabledTooltip: this.pendingConfirmation
                ? this.$t('appointment.pending_action_disabled')
                : this.$t('appointment.disabled_unmark_no_show_message'),
              label: this.appointment.noShow
                ? this.$t('appointment.no_show.unmark')
                : this.$t('appointment.no_show.mark')
            },
            {
              name: 'delete',
              icon: 'delete',
              label: this.$t('global.actions.delete'),
              hide: this.appointment.treatwell && this.isOldAppointment
            }
          ].filter((item) => !item.hide)
        : [];
    },
    isActive() {
      return (
        this.appointment &&
        !this.appointment.deleted &&
        !this.appointment.cancelled
      );
    },
    pendingConfirmation: {
      get() {
        return this.appointment?.pendingConfirmation;
      },
      set(value) {
        this.appointment.pendingConfirmation = !!value;
      }
    },
    hasReport() {
      return this.appointment?.report?.id;
    },
    isOldAppointment() {
      return dayjs(this.appointment.startAt).isBefore(dayjs());
    },
    hasPaidInvoice() {
      return (
        this.appointment &&
        !!this.appointment.orders.find((order) => order.paid && !order.credit)
      );
    },
    unpaidInvoice() {
      return this.appointment.orders.find(
        (order) => !order.paid && !order.credit
      );
    },
    labels() {
      const labels = [];
      if (this.appointment && this.appointment.noShow) {
        labels.push('no_show');
      }
      if (this.appointment && this.appointment.online) {
        labels.push('online');
      }
      if (this.appointment && this.appointment.cancelled) {
        labels.push('cancelled');
      }
      if (this.appointment && this.appointment.deleted) {
        labels.push('deleted');
      }
      return labels;
    },
    location() {
      return this.appointment && this.appointment.locationId
        ? this.locationById(this.appointment.locationId)
        : null;
    },
    customer() {
      return this.appointment && this.appointment.customer
        ? this.appointment.customer
        : null;
    },
    resources() {
      let resources = [];
      if (this.appointment?.parts?.length) {
        this.appointment.parts.forEach((part) => {
          resources = [...resources, ...part.resources];
        });
      }
      return resources;
    },
    employeeNames() {
      return [
        ...new Set(
          this.resources
            .filter((resource) => resource.type === 'employee')
            .map((resource) => resource.name)
        )
      ];
    },
    details() {
      if (!this.appointment) {
        return null;
      } else {
        const details = [];

        if (this.appointment.rrule) {
          details.push({
            label: this.$t('appointment.features.recurring'),
            value: this.$t(
              `calendar.recurring_confirmation.day.${this.appointment.rrule.freq}.${this.appointment.rrule.interval === 1 ? 'one' : 'other'}`,
              { count: this.appointment.rrule.interval }
            ),
            icon: { name: 'repeat' }
          });
        }

        details.push({
          label: this.$t('global.date'),
          value: this.originalStartAt || this.appointment.startAt,
          type: 'date'
        });
        details.push({
          label: this.$t('global.time'),
          value: `${this.filters.time(this.appointment.startAt)} - ${this.filters.time(this.appointment.endAt)}`
        });

        if (this.location && this.locations.length > 1) {
          details.push({
            label: this.$t('global.items.location', 1),
            value: this.location.internalName
          });
        }

        if (this.appointment.parts) {
          let preference;
          let resourceChanged = false;
          let iconTooltip;

          if (this.appointment.selectedResourceId === 0) {
            preference = 'no';
            iconTooltip = this.$t(
              'appointment.features.no_resource_preference'
            );
          } else if (this.appointment.selectedResourceId) {
            preference = 'yes';

            if (
              this.appointment.selectedResourceId !==
              this.appointment.parts[0].resource_id
            ) {
              resourceChanged = true;
              iconTooltip = this.$t(
                'appointment.features.resource_preference_changed'
              );
            } else {
              iconTooltip = this.$t('appointment.features.resource_preference');
            }
          }

          const employeeDetail = {
            label: this.$t('global.items.employee', this.employeeNames.length),
            value: this.employeeNames.join(', ')
          };

          if (preference) {
            employeeDetail.icon = {
              name: preference === 'yes' ? 'lock' : 'unlock',
              red: resourceChanged,
              tooltip: iconTooltip
            };
          }

          details.push(employeeDetail);
        }

        return details;
      }
    },
    appointmentOrders() {
      return this.appointment?.orders
        ? this.appointment.orders.filter((order) => !order.draft)
        : [];
    }
  },
  methods: {
    ...mapActions(usePreCreateStore, { setPreCreateState: 'setState' }),
    onActionClick(action) {
      switch (action) {
        case 'checkout':
          this.onCheckoutClick();
          break;
        case 'move':
          this.moveAppointment();
          break;
        case 'copy':
          this.copyAppointment();
          break;
        case 'no-show':
          this.noshowAppointment();
          break;
        case 'delete':
          this.deleteAppointment();
          break;
      }

      this.mixPanelTrack(action);
    },
    onClose() {
      const { currentOnboardingFlow } = useOnboardingStore();
      if (currentOnboardingFlow === 'appointment') {
        setTimeout(() => {
          eventBus.$emit('open-task-menu');
        }, 500);
      }
    },
    addCustomer(customer) {
      this.appointment.customer = customer;

      updateAppointment({
        input: {
          id: this.appointment.id,
          customerId: customer.id
        }
      });
    },
    copyAppointment() {
      this.setPreCreateState({
        action: 'COPY',
        appointment: this.appointment
      });

      this.$router.push({
        name: 'calendar'
      });
    },
    moveAppointment() {
      this.setPreCreateState({
        action: 'RESCHEDULE',
        appointment: {
          ...this.appointment,
          original: true,
          originalStartAt: this.originalStartAt
        }
      });

      this.$router.push({
        name: 'calendar'
      });
    },
    onLabelSelect(label) {
      updateAppointment({
        input: {
          id: this.appointment.id,
          calendarLabelId: label?.id || null
        }
      }).then(() => {
        this.appointment.calendarLabel = label || null;
      });
    },
    onCheckoutClick() {
      const query = this.unpaidInvoice
        ? { orderId: this.unpaidInvoice.id }
        : { appointmentId: this.appointment.id };
      this.$router.push({ name: 'register', query });
    },
    deleteAppointment() {
      if (
        this.appointment.treatwell &&
        !this.treatwellStatus.freeCommissionRemainingDays
      ) {
        this.showRefundModal = true;
      } else {
        modal('confirmation', {
          type: 'delete',
          subMessage: this.appointment.treatwell
            ? this.$t('appointment.delete_treatwell_warning')
            : '',
          info:
            this.appointment.treatwell &&
            this.treatwellStatus.freeCommissionRemainingDays
              ? this.$t('appointment.treatwell_cancel_policy')
              : '',
          item: this.$t('global.items.appointment')
        }).then(() => {
          deleteAppointment({ id: this.appointment.id }).then(() => {
            this.onDeleted();
          });
        });
      }
    },
    closeRefundModal() {
      this.showRefundModal = false;
    },
    onDeleted() {
      this.$emit('deleted');
      this.$router.push({
        name: this.parentRouteName
      });
    },
    noshowAppointment() {
      if (this.appointment.treatwell) {
        this.dispatchNoShowWithConfirmation();
      } else {
        this.dispatchNoShowAppointment();
      }
    },
    dispatchNoShowWithConfirmation() {
      modal('confirmation', {
        message: this.getNoShowWithConfirmationMessage()
      }).then(() => {
        this.dispatchNoShowAppointment();
      });
    },
    getNoShowWithConfirmationMessage() {
      let message = this.$t('appointment.no_show.confirm_mark');
      if (this.customer) {
        message +=
          ' ' +
          this.$t('appointment.no_show.customer_will_be_notified', {
            name: this.customer.firstName
          });
      }
      return message;
    },
    dispatchNoShowAppointment() {
      const noShow = !this.appointment.noShow;

      updateAppointment({
        input: {
          id: this.appointment.id,
          state: noShow ? 'no_show' : 'active'
        }
      }).then(() => {
        this.appointment.noShow = noShow;
        this.$emit('updated');
      });
    },
    mixPanelTrack(button) {
      this.mixpanel.track('Appointment modal button click', {
        button,
        appointment_id: this.appointment.id
      });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  &.smallScreen {
    padding: $spacing * 0.5;
  }
}

.customer {
  padding: $spacing;

  .base.smallScreen & {
    display: flex;
    flex-wrap: wrap;
  }
}

.customerInner {
  padding: $spacing * 0.5 0;

  .base.smallScreen & {
    display: flex;
    flex-wrap: wrap;
  }
}

.modalDetailsHead {
  margin-bottom: $spacing * 0.5;
}

.modalDetailsHead,
.modalDetailsBody {
  display: flex;

  & > * {
    width: 25%;
  }
}

.customerHeader {
  padding: $spacing $spacing 0;
}

.noCustomer {
  padding: $spacing;
}

.servicesSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5 * $spacing;
}

.treatwellPayment {
  margin-bottom: 0.5 * $spacing;
}
</style>
