<template>
  <BaseModal
    :heading="
      $t('global.actions.delete_item', {
        item: $t('global.items.appointment', 1)
      })
    "
    small
    testId="request-refund"
    @close="closeModal"
  >
    <div :class="$style.disclaimers">
      <BaseHeading mb>
        {{
          $t('appointment.delete_treatwell_confirmation.commission_disclaimer')
        }}
      </BaseHeading>
      <BaseText mb iconBefore="scissors">
        {{ $t('appointment.delete_treatwell_warning') }}
      </BaseText>
      <BaseText mb iconBefore="eye-off">
        {{ $t('appointment.delete_treatwell_confirmation.ranking_disclaimer') }}
      </BaseText>
      <BaseText mb iconBefore="person-add">
        {{
          $t(
            'appointment.delete_treatwell_confirmation.customer_info_disclaimer'
          )
        }}
      </BaseText>
      <BaseText href="/terms" iconBefore="info">
        {{
          $t(
            'appointment.delete_treatwell_confirmation.faq.commission_help_link'
          )
        }}
      </BaseText>
      <div v-if="amount > 0" :class="$style.form">
        <BaseAlert
          v-if="inCancellationPeriod"
          mt
          :text="
            $t('appointment.refund.details', {
              amount: filters.currency(amount)
            })
          "
          v-test="'request-refund-details'"
        />
        <div v-else>
          <BaseRadio
            v-model="selectedOption"
            mt
            :label="
              $t('appointment.refund.info', {
                amount: filters.currency(amount)
              })
            "
            :options="options"
            v-test="'request-refund-options'"
          />
          <BaseText
            v-show="showErrorMessage"
            mt
            color="error"
            v-test="'request-refund-error'"
          >
            {{ $t('appointment.refund.error') }}
          </BaseText>
        </div>
      </div>
    </div>
    <div :class="$style.faq">
      <BaseAccordion
        ref="baseAccordion"
        v-model="faqAccordion"
        :titles="[
          $t(
            'appointment.delete_treatwell_confirmation.faq.client_cancel.question'
          ),
          $t(
            'appointment.delete_treatwell_confirmation.faq.client_wrong_treatment.question'
          )
        ]"
      >
        <template #1>
          <BaseText>
            {{
              $t(
                'appointment.delete_treatwell_confirmation.faq.client_cancel.answer'
              )
            }}
          </BaseText>
        </template>
        <template #2>
          <BaseText>
            {{
              $t(
                'appointment.delete_treatwell_confirmation.faq.client_wrong_treatment.answer'
              )
            }}
          </BaseText>
        </template>
      </BaseAccordion>
    </div>
    <template #footer>
      <BaseButton
        color="error"
        class="ml"
        @click="onConfirmClick"
        v-test="'request-refund-confirm'"
      >
        {{ $t('appointment.refund.confirm') }}
      </BaseButton>
      <BaseButton @click="moveAppointment" v-test="'reschedule-appointment'">
        {{ $t('global.actions.move') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import eventBus from '@/event-bus';
import { useCompanyStore } from '@/stores/company';
import { mapActions, mapState } from 'pinia';
import { deleteAppointment } from '@/modules/calendar/actions/appointments';

import { defineComponent } from 'vue';
import { Action, usePreCreateStore } from '@/stores/calendar-pre-create';

export default defineComponent({
  props: {
    appointment: {
      type: Object,
      required: true
    },
    appointmentId: {
      type: Number,
      required: true
    },
    appointmentStartAt: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    }
  },
  emits: ['onClose', 'onSubmit'],
  setup() {
    return {
      v$: useVuelidate(),
      filters
    };
  },
  data() {
    return {
      faqAccordion: [],
      selectedOption: undefined,
      isSubmitted: false,
      showErrorMessage: false
    };
  },
  validations() {
    return {
      selectedOption: {
        required
      }
    };
  },
  watch: {
    selectedOption() {
      this.showErrorMessage = false;
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    options() {
      return [
        { value: true, label: this.$t('appointment.refund.yes') },
        { value: false, label: this.$t('appointment.refund.no') }
      ];
    },
    cancellationHours() {
      return this.companySettings.agenda?.cancellationHours;
    },
    inCancellationPeriod() {
      return dayjs().isBefore(
        dayjs(this.appointmentStartAt).subtract(this.cancellationHours, 'hours')
      );
    }
  },
  methods: {
    ...mapActions(usePreCreateStore, { setPreCreateState: 'setState' }),
    closeModal() {
      this.$emit('onClose');
    },
    moveAppointment() {
      this.setPreCreateState({
        action: Action.Reschedule,
        appointment: {
          ...this.appointment,
          original: true,
          originalStartAt: this.appointmentStartAt
        }
      });

      this.$router.push({
        name: 'calendar'
      });
    },
    onConfirmClick() {
      this.isSubmitted = true;
      if (this.amount > 0 && !this.inCancellationPeriod && this.v$.$invalid) {
        this.showErrorMessage = true;
        return;
      }
      const shouldRefund = this.inCancellationPeriod || this.selectedOption;

      deleteAppointment({ id: this.appointmentId, refund: shouldRefund }).then(
        () => {
          this.$emit('onSubmit');
          eventBus.$emit('refresh-appointments');
        }
      );
    }
  }
});
</script>

<style lang="scss" module>
.disclaimers {
  margin-bottom: $spacing;
}
</style>
