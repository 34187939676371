import type { App } from 'vue';

let scriptAdded = false;

const loadScript = (intercomId: string) => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://widget.intercom.io/widget/${intercomId}`;
  document.getElementsByTagName('head')[0].appendChild(script);
  scriptAdded = true;

  script.onload = () => {
    if (window.Intercom) {
      window.Intercom('boot', { app_id: intercomId });
    }
  };
};

type Options = {
  intercomId: string;
};

export type Intercom = {
  hideLauncher(): void;
  showLauncher(): void;
  setContext(arg0: any): void;
  trackEvent(event: string, metadata: any): void;
  startSurvey(options: number): void;
};

export default {
  install: (app: App<Element>, { intercomId }: Options) => {
    if (intercomId && !scriptAdded) {
      loadScript(intercomId);
    }

    const intercom: Intercom = {
      hideLauncher() {
        if (window.Intercom) {
          window.Intercom('update', {
            hide_default_launcher: true
          });
        }
      },
      showLauncher() {
        if (window.Intercom) {
          window.Intercom('update', {
            hide_default_launcher: false
          });
        }
      },
      setContext(options: any) {
        if (window.Intercom) {
          window.Intercom('update', options);
        }
      },
      trackEvent(event: string, metadata: any) {
        if (window.Intercom) {
          window.Intercom('trackEvent', event, metadata);
        }
      },
      startSurvey(options: any) {
        if (window.Intercom) {
          window.Intercom('startSurvey', options);
        }
      }
    };

    app.provide('intercom', intercom);
  }
};
