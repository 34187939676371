import gql from 'graphql-tag';

export const resourceFragment = gql`
  fragment resource on Resource {
    bookable
    color
    email
    gender
    id
    initials
    name
    phone
    picture
    sortOrder
    state
    type
    updatedAt
    healthcareCode
    jobRole
  }
`;
export const customerFragment = gql`
  fragment customer on Customer {
    activity
    alertNotes
    blocked
    email
    firstName
    fullName
    id
    lastEmailStatus
    lastSmsStatus
    lastName
    loyaltyPoints
    mobilePhone
    phone
  }
`;

export const partFragment = gql`
  fragment part on AppointmentPart {
    allocations {
      id
      requirement {
        id
        primary
        resources {
          id
        }
        type
        state
      }
      resourceId
    }
    buffer
    duration
    durationFinish
    durationProcessing
    durationSetup
    id
    service {
      buffer
      category {
        id
        name
        sortOrder
      }
      deleted
      duration
      durationFinish
      durationProcessing
      durationSetup
      sortOrder
      id
      name
      price
      requiresProcessingTime
    }
    sortOrder
  }
`;

export const appointmentFragment = gql`
  fragment appointment on Appointment {
    calendarLabel {
      color
      id
      title
    }
    customer {
      ...customer
    }
    customerMember {
      firstName
      lastName
    }
    duration
    endAt
    id
    locationId
    noShow
    notes
    online
    parts {
      ...part
    }
    price
    startAt
    treatwell
  }
  ${partFragment}
  ${customerFragment}
`;

export const calendarAppointmentFragment = gql`
  fragment calendarAppointment on CalendarAppointment {
    duration
    durationEditable
    end
    exdate
    extendedProps {
      color
      customerId
      customerName
      customerMember {
        firstName
        lastName
      }
      entryId
      eventDuration
      features
      id
      locationId
      originalStartAt
      pending
      request
      resourceIds
      rrule
      totalDuration
      type
    }
    id
    partId
    resourceEditable
    resourceIds
    rrule
    start
    startEditable
    title
  }
`;

export const reportFragment = gql`
  fragment report on AppointmentReport {
    id
    report
    appointment {
      id
      startAt
      serviceNames
      parts {
        resources {
          name
        }
      }
      calendarAppointments {
        ...calendarAppointment
      }
    }
    employeeId
    attachments {
      id
      publicId
      filename
    }
  }
  ${calendarAppointmentFragment}
`;

export const absenceFragment = gql`
  fragment absence on Absence {
    allDay
    chore
    endAt
    id
    locationId
    name
    resourceId
    rrule
    startAt
  }
`;

export const calendarAbsenceFragment = gql`
  fragment calendarAbsence on CalendarAbsence {
    allDay
    duration
    durationEditable
    end
    exdate
    extendedProps {
      chore
      color
      company
      entryId
      eventDuration
      importProvider
      importRemoteUrl
      imported
      locationId
      name
      originalStartAt
      recurs
      rrule
      type
    }
    id
    resourceEditable
    resourceId
    rrule
    start
    startEditable
    title
  }
`;

export const noteFragment = gql`
  fragment note on Note {
    completedAt
    customerId
    date
    id
    resourceId
    text
  }
`;

export const calendarNoteFragment = gql`
  fragment calendarNote on CalendarNote {
    allDay
    durationEditable
    extendedProps {
      color
      company
      customerId
      customerName
      done
      entryId
      resourceId
      type
    }
    id
    resourceEditable
    resourceId
    start
    startEditable
    title
  }
`;

export const calendarWaitingListFragment = gql`
  fragment calendarWaitingList on CalendarWaitingList {
    allDay
    durationEditable
    extendedProps {
      amount
      type
    }
    id
    resourceEditable
    start
    startEditable
  }
`;

export const formSubmissionFragment = gql`
  fragment formSubmission on FormSubmission {
    answers {
      ... on StringAnswer {
        stringAnswer: answer
        id
        questionId
      }
      ... on BooleanAnswer {
        booleanAnswer: answer
        id
        questionId
      }
      ... on ArrayAnswer {
        arrayAnswer: answer
        id
        questionId
      }
    }
    id
    createdAt
    appointment {
      id
      startAt
      calendarAppointments {
        ...calendarAppointment
      }
    }
    template {
      name
      formType
      id
    }
    online
  }
  ${calendarAppointmentFragment}
`;

export const importFragment = gql`
  fragment import on Import {
    createdAt
    id
    productsCount
    state
  }
`;

export const productFragment = gql`
  fragment product on Product {
    category {
      name
    }
    costPrice
    id
    name
    outOfStock
    price
    stockAmount
    stockEnabled
    stockMaximum
    stockMinimum
    vatRate {
      name
      percentage
      selectedByDefault
    }
  }
`;

export const categoryFragment = gql`
  fragment category on ServiceCategory {
    id
    name
    color
    sortOrder
  }
`;

export const serviceFragment = gql`
  fragment service on Service {
    bookable
    bookableLocationIds
    buffer
    category {
      id
    }
    description
    duration
    durationFinish
    durationProcessing
    durationSetup
    extraInformation
    favorite
    id
    medical
    name
    offeringEnabled
    price
    productId
    rebookEnabled
    rebookPeriod
    resourceAdjustments {
      id
      price
      resourceId
    }
    requirements {
      id
      primary
      resources {
        id
        state
      }
      type
    }
    requiresProcessingTime
    serviceId
    sortOrder
    vatRateId
    rwgName
  }
`;

export const emailTemplateFragment = gql`
  fragment emailTemplate on EmailTemplate {
    buttonTitle
    buttonUrl
    color
    deliverAt
    discountCode {
      code
      id
    }
    discountMessage
    emailType
    filters {
      filterName
      values
    }
    hasButton
    hasDeliverySchedule
    hasDiscount
    hasFooter
    hasImage
    hasLogo
    id
    imageUrl
    message
    name
    subject
    dispatchType
  }
`;
