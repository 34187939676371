<template>
  <BaseModalLarge
    :heading="
      $t(
        canUpgradeSubscription
          ? 'subscription.heading_upgrade'
          : 'subscription.heading'
      )
    "
    :unclosable="isTrialExpired"
    testId="subscription"
    noPadding
    parentRoute="dashboard"
    @close="mixpanel.track('Subscription flow - Closed')"
  >
    <EmptyPageContent
      v-if="isNativeAppIOS"
      imageName="training-employee"
      :text="{
        title: $t('ios.subscription_blocked.heading'),
        description: $t('ios.subscription_blocked.description')
      }"
    />
    <div
      v-else-if="plansData.length"
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.mediumScreen]: $screen === 'm',
          [$style.hasSidebar]: showSummary
        }
      ]"
    >
      <div :class="$style.contentWrapper">
        <div :class="$style.content">
          <div
            v-if="trialExpiredDaysAgo >= 0 && !isSubscribed"
            :class="$style.alertHolder"
          >
            <BaseAlert
              :text="
                $t(
                  'subscription.trial_expired',
                  { days: trialExpiredDaysAgo },
                  trialExpiredDaysAgo
                )
              "
              :primaryAction="
                canReactivateTrial ? $t('subscription.extend_trial') : undefined
              "
              color="warning"
              @primaryAction="reactivateTrial"
              v-test="'trial-expired-alert'"
            />
          </div>
          <div v-if="hasPaymentError" :class="$style.alertHolder">
            <BaseAlert
              color="error"
              :text="$t('subscription.payment_error')"
              v-test="'subscription-payment-error-alert'"
            />
          </div>
          <StripeErrors />
          <router-view />
        </div>
      </div>
      <div v-show="showSummary" :class="$style.side">
        <Summary />
      </div>
    </div>
    <BaseSpinner v-else />
  </BaseModalLarge>
</template>

<script lang="ts">
export default defineComponent({
  name: 'Subscription'
});
</script>

<script lang="ts" setup>
import { ref, computed, inject, defineComponent } from 'vue';
import { flash } from '@/helpers/ui';
import BaseAlert from '@/components/BaseAlert.vue';
import Summary from './Summary.vue';
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useSubscriptionStore } from './store';
import { useStripeStore } from './stripe/store';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';
import { storeToRefs } from 'pinia';
import StripeErrors from './stripe/StripeErrors.vue';
import { SubscriptionPlan } from '@/types';
import unleash from '@/unleash';
import { useNativeAppStore } from '@/stores/native-app';
import EmptyPageContent from '@/components/EmptyPageContent.vue';

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { isNativeAppIOS } = useNativeAppStore();

const mixpanel = inject<any>('mixpanel');
const hasPaymentError = ref(false);
const {
  companySettings,
  isTrialExpired,
  isSubscribed,
  trialExpiredDaysAgo,
  canUpgradeSubscription
} = useCompanyStore();
const { plansData } = storeToRefs(useSubscriptionStore());

const routeName = computed(() => route.name);
const showSummary = computed(
  () =>
    routeName.value === 'subscription-payment' ||
    routeName.value === 'subscription-upgrade' ||
    routeName.value === 'stripe-checkout'
);
const canReactivateTrial = computed(
  () => !companySettings.general.reactivatedAt && trialExpiredDaysAgo >= 7
);

const reactivateTrialMutation = gql`
  mutation reactivateTrial($input: ReactivateTrialInput!) {
    reactivateTrial(input: $input) {
      errors
    }
  }
`;

const { mutate, onDone: onReactivateTrial } = useMutation(
  reactivateTrialMutation,
  () => ({
    variables: {
      input: {}
    }
  })
);

onReactivateTrial((response) => {
  if (response?.data.reactivateTrial.errors?.length > 0) {
    flash(t('global.flash.reactivated_before'));
  } else {
    const { getUser } = useUserStore();
    getUser().then(() => {
      router.push({ name: 'dashboard' });
    });
    flash(t('global.flash.reactivated_trial'));
  }
});

const reactivateTrial = () => {
  mixpanel.track('Subscription flow - Extend trial clicked');
  mutate();
};

const { getData } = useSubscriptionStore();
getData();

const { getStripePricesData } = useStripeStore();
getStripePricesData();

const { selectedPlan } = storeToRefs(useStripeStore());
selectedPlan.value = SubscriptionPlan.Pro;

if (route.query.payment === 'failure') {
  hasPaymentError.value = true;
}

const showTerminalSection = unleash.isEnabled('SubscriptionTerminal');

mixpanel.track('Subscription flow - Visited', {
  stripe_checkout: true,
  is_subscription_upgrade_flow: canUpgradeSubscription,
  terminalSelection: showTerminalSection
});
</script>

<style lang="scss" module>
.base {
  height: 100%;

  &:not(.smallScreen) {
    display: flex;
    justify-content: space-between;

    &:not(.hasSidebar) {
      flex-direction: column;
    }
  }
}

$sidebarWidth: 350px;
$sidebarWidthMedium: 294px;

.contentWrapper {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .base:not(.smallScreen) & {
    min-width: 60%;
  }

  .base:not(.mediumScreen):not(.smallScreen).hasSidebar & {
    width: calc(100% - #{$sidebarWidth});
  }

  .base.mediumScreen.hasSidebar & {
    width: calc(100% - #{$sidebarWidthMedium});
  }

  .base.smallScreen:not(.hasSidebar) & {
    height: calc(100% - 94px);
  }

  .base.smallScreen.hasSidebar & {
    height: calc(100% - 135px);
  }
}

.content {
  .base:not(.smallScreen) & {
    display: flex;
    flex-direction: column;
  }
}

.side {
  background-color: white;
  width: 100%;

  .base:not(.smallScreen) & {
    max-width: 40%;
    border-radius: 0 0 10px 0;
    border-left: 1px solid $color-border;
  }

  .base:not(.mediumScreen):not(.smallScreen) & {
    width: $sidebarWidth;
  }

  .base.mediumScreen & {
    width: $sidebarWidthMedium;
  }

  .base.smallScreen & {
    border-radius: 0 0 10px 10px;
    max-height: 100%;
    overflow-y: auto;
  }
}

.footer {
  background: white;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .base:not(.smallScreen) & {
    padding: $spacing * 1.5;
    align-items: center;
    border-top: 1px solid $color-border;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.side,
.footer {
  .base.smallScreen & {
    width: 100%;
    box-shadow: $shadow;
  }
}
.logout {
  margin-right: $spacing;
}

.alertHolder {
  .base:not(.smallScreen) & {
    padding: $spacing $spacing * 1.5 0;
  }

  .base.smallScreen & {
    padding: $spacing $spacing * 0.5 0;
  }
}

.nextStepHolder {
  .base.smallScreen & {
    flex-grow: 1;
  }
}
</style>
