<template>
  <div v-if="showComponent">
    <BaseCheckbox
      v-model="isEnabled"
      :label="
        $t('admin.booking.allow_new_customers.existing_only_per_employee')
      "
      v-test="'booking-settings-existingCustomersPerEmployee'"
    />
    <BaseDropdown
      v-if="isEnabled"
      v-model="selectedEmployeeIds"
      :options="
        employees.map((employee) => ({
          value: employee.id,
          label: employee.name
        }))
      "
      resources
      mt
      v-test="'booking-settings-existingCustomersPerEmployeeDropdown'"
    />
  </div>
</template>

<script setup lang="ts">
import { useResourcesStore } from '@/stores/resources';
import unleash from '@/unleash';

const props = defineProps<{
  allowNewCustomers: boolean;
  enabled: boolean;
  modelValue: number[];
}>();

const emit = defineEmits(['update:modelValue', 'update:enabled']);
const { hasSingleEmployee } = useResourcesStore();

const showComponent = computed(
  () =>
    !props.allowNewCustomers &&
    !hasSingleEmployee &&
    unleash.isEnabled('ExistingCustomersPerEmployee')
);

const { employees } = useResourcesStore();
const selectedEmployeeIds = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  }
});

const isEnabled = computed({
  get: () => props.enabled,
  set: (value) => {
    emit('update:enabled', value);
  }
});
</script>
