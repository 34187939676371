<template>
  <BaseAlert
    v-if="discountCode"
    mt
    icon="percentage"
    :text="
      $t('appointment.discount_code_used', {
        code: discountCode.code,
        percentage: discountCode.discountPercentage
      })
    "
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    discountCode: {
      type: Object,
      required: false
    }
  }
});
</script>
