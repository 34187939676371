<template>
  <div :class="$style.base">
    <BaseCard>
      <BaseGrid container>
        <BaseGrid>
          <BaseText oneLine iconBefore="email" bold :mb="$screen !== 's'">
            {{ title }}
          </BaseText>
        </BaseGrid>
        <BaseGrid :alignRight="$screen !== 's'" alignTop>
          <div :class="{ [$style.isSmall]: $screen === 's' }">
            <BaseLabel
              v-if="enabled"
              color="success"
              v-test="'enabledStateLabel'"
            >
              {{ $t('global.enabled') }}
            </BaseLabel>
            <BaseLabel v-else v-test="'disabledStateLabel'">
              {{ $t('global.disabled') }}
            </BaseLabel>
          </div>
        </BaseGrid>
      </BaseGrid>
      <BaseText>
        {{ description }}
      </BaseText>
      <BaseButton
        :routerLink="routerLink"
        mt
        fullWidth
        color="inverted"
        @click="clickTemplate"
        v-test="`enable-${routerLink.name}`"
      >
        {{ $t('global.settings') }}
      </BaseButton>
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import { inject, defineProps } from 'vue';
import type { LocationQueryRaw } from 'vue-router';
import { useContactEmailVerification } from '../useContactEmailVerification';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const mixpanel = inject<any>('mixpanel');
const { contactEmailVerified, verifyContactEmail } =
  useContactEmailVerification();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    enabled: boolean;
    routerLink: LocationQueryRaw;
    title?: string;
    description?: string;
    mixpanelEvent: string;
  }>(),
  {
    title: '',
    description: '',
    mixpanelEvent: 'Edit template clicked'
  }
);

const clickTemplate = () => {
  if (
    !contactEmailVerified &&
    props.routerLink.name !== 'rebook-reminder-dashboard'
  ) {
    modal('confirmation', {
      type: 'sendEmail',
      subMessage: t('email_templates.verification_modal.sub_message'),
      message: t('email_templates.verification_modal.message')
    }).then(() => {
      verifyContactEmail();
    });
  } else {
    mixpanel.track(props.mixpanelEvent);
  }
};
</script>

<style lang="scss" module>
.base {
  max-width: 400px;
}

.isSmall {
  margin-bottom: $spacing * 0.5;
}
</style>
