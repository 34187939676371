<template>
  <div v-if="!isEnabled">
    <div
      v-if="
        hasRegister && !hasGateway && !unleash.isEnabled('SalonizedPayOnline')
      "
      v-test="'booking-settings-onlinePaymentWarning'"
    >
      <BaseAlert v-if="description" :mb="0.5" :text="description" />
      <BaseButton
        :href="`${config.backendUrl}/auth/mollie`"
        color="inverted"
        mr
        :mb="0.5"
        v-test="'enable-mollie-button'"
      >
        {{ $t('admin.booking.payment_providers.enable_mollie') }}
      </BaseButton>
      <BaseButton
        v-if="showStripeButton"
        :href="`${config.backendUrl}/auth/stripe`"
        color="inverted"
        :mb="0.5"
        v-test="'enable-stripe-button'"
      >
        {{ $t('admin.booking.payment_providers.enable_stripe') }}
      </BaseButton>
    </div>
    <BaseAlert
      v-if="!hasRegister"
      color="warning"
      :text="$t('admin.booking.upgrade_warning')"
      :primaryAction="{
        text: $t('admin.booking.upgrade_button'),
        routerLink: { name: 'subscription' }
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, watch } from 'vue';
import config from '@/config';
import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';

defineProps<{
  modelValue: boolean;
  description?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const { result: currentUser } = useQuery(
  gql`
    query getCurrentUser {
      currentUser {
        company {
          paymentGateway
        }
      }
    }
  `,
  null,
  {
    fetchPolicy: 'cache-first'
  }
);

const {
  company: { country },
  isMarketLaunchCountry
} = useCompanyStore();

const showStripeButton = computed(() => {
  const companyCountryNoStripeCheck =
    country === 'nl' ||
    country === 'be' ||
    country === 'de' ||
    isMarketLaunchCountry;
  return (
    unleash.isEnabled('ShowStripeFeatures') || !companyCountryNoStripeCheck
  );
});

const { hasFeatureFlag } = useUserStore();

const hasGateway = computed(
  () => !!currentUser.value?.currentUser.company.paymentGateway
);
const hasRegister = computed(() => hasFeatureFlag('module-register'));

const isEnabled = computed(() => hasRegister.value && hasGateway.value);
watch(
  isEnabled,
  (isEnabled) => {
    emit('update:modelValue', isEnabled);
  },
  {
    immediate: true
  }
);
</script>
