import { modal } from '@/helpers/ui';
import { useMutation } from '@vue/apollo-composable';
import { SEND_CONTACT_EMAIL_VERIFICATION_EMAIL } from '../messages/email-templates/graphql';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

export const useContactEmailVerification = () => {
  const { company } = storeToRefs(useCompanyStore());
  const { t } = useI18n();

  const { mutate } = useMutation(SEND_CONTACT_EMAIL_VERIFICATION_EMAIL);
  const verifyContactEmail = () => {
    mutate({ input: {} }).then(() => {
      modal('warning', {
        message: t('email_templates.verification_success_modal.message'),
        subMessage: t('email_templates.verification_success_modal.sub_message')
      });
    });
  };

  return {
    contactEmailVerified: company.value?.contactEmailVerified,
    verifyContactEmail
  };
};
