<template>
  <BaseModal
    :heading="$t(`global.items.${resourceType}`, 1)"
    :loading="$apollo.loading"
    small
    :testId="resourceType"
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <ResourcePrice
      v-if="showPriceInfo"
      :type="resourceType"
      learnMoreLink
      small
      :mb="1"
    />
    <BaseForm @submit="submit">
      <BaseGrid container>
        <BaseGrid :size="6" :sSize="12">
          <BaseInput
            v-model="formData.name"
            :label="$t('global.name')"
            required
            :maxLength="255"
            mb
            v-test="'resource-name'"
          />
          <div v-if="isEmployee">
            <BaseDropdown
              v-model="formData.gender"
              :options="genderOptions"
              :label="$t('global.gender')"
              mb
              v-test="'resource-gender'"
            />
            <BaseInput
              v-model="formData.phone"
              :label="$t('global.phone_number')"
              type="tel"
              mb
              v-test="'resource-phone'"
            />
            <BaseInput
              v-model="formData.email"
              :label="$t('global.email_address')"
              type="email"
              mb
              v-test="'resource-email'"
            />
            <div v-if="company.medical">
              <BaseInput
                v-model="formData.healthcareCode"
                :label="$t('resource.healthcare_code')"
                :info="$t('resource.healthcare_code_info')"
                mb
                v-test="'resource-healthcare-code'"
              />
              <BaseInput
                v-model="formData.jobRole"
                :label="$t('resource.job_role')"
                v-test="'resource-job-role'"
              />
            </div>
          </div>
          <div v-if="showTypeSelect">
            <BaseRadio
              v-model="formData.type"
              :options="typeOptions"
              :label="$t('global.type')"
              inline
              v-test="'resource-type'"
            />
          </div>
        </BaseGrid>
        <BaseGrid :size="6" :sSize="12" alignRight :mt="0.5">
          <div :class="$style.sectionRight">
            <AvatarWithUploader
              :resource="formData"
              @setPicture="setPicture"
              v-test="'resource-avatar'"
            />
            <ColorSelection
              v-model:colorSetting="colorSetting"
              v-model:color="formData.color"
              :modelType="resourceType"
              mt
              v-test="'resource-color'"
            />
          </div>
        </BaseGrid>
        <BaseGrid :size="12">
          <BaseCard gray mb>
            <BaseCheckbox
              v-model="formData.bookable"
              :label="$t(`${resourceType}.allow_online_booking`)"
              v-test="'resource-bookable'"
            />
          </BaseCard>
          <RosterOptions
            v-model:customRoster="formData.customRoster"
            v-model:rosterLocation="formData.rosterLocationId"
            :resourceType="resourceType"
            :multiLocation="multiLocation"
          />
          <BaseText size="l" bold :mt="2">
            {{ $t(`${resourceType}.services_availability_title`) }}
          </BaseText>
          <BaseText mb>
            {{ $t(`${resourceType}.services_availability_info`) }}
          </BaseText>
          <Services
            v-model="formData.serviceIds"
            :disableDeselect="disableDeselectServices"
            mb
            v-test="'resource-services'"
          />
          <Locations
            v-if="multiLocation"
            v-model="formData.locationIds"
            gray
            v-test="'resource-locations-list'"
          />
          <BaseText
            v-if="showLocationIdsError"
            color="error"
            :mt="0.5"
            v-test="'resource-locations-error'"
          >
            *{{ $t('resource.location_error') }}
          </BaseText>
        </BaseGrid>
      </BaseGrid>
    </BaseForm>
    <template #footer>
      <Footer
        :resource="resource"
        :isLoading="isLoading"
        @onSubmit="submit"
        @close="close"
      />
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { resourceFragment } from '@/graphql-fragments';
import AvatarWithUploader from './AvatarWithUploader.vue';
import Footer from './Footer.vue';
import Services from './services/index.vue';
import Locations from '@/modules/admin/_shared/LocationSelect.vue';
import RosterOptions from './RosterOptions.vue';
import ColorSelection from '@/modules/admin/_shared/ColorSelection.vue';
import ResourcePrice from '@/modules/admin/resources/ResourcePrice.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState, mapActions } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useServicesStore } from '@/stores/services';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResourceForm',
  components: {
    AvatarWithUploader,
    ColorSelection,
    Footer,
    Services,
    Locations,
    ResourcePrice,
    RosterOptions
  },
  inject: ['mixpanel'],
  props: {
    type: {
      type: String,
      validator: (value) =>
        !value || ['employee', 'room', 'equipment'].includes(value)
    },
    parentRouteName: String
  },
  emits: ['created', 'updated', 'close'],
  setup() {
    const { createResource, updateResource } = useResourcesStore();
    return {
      createResource,
      updateResource,
      v$: useVuelidate()
    };
  },
  data() {
    return {
      isLoading: false,
      colorSetting: null,
      showLocationIdsError: false,
      formData: {
        name: null,
        gender: null,
        phone: null,
        email: null,
        color: null,
        bookable: true,
        customRoster: false,
        picture: null,
        type: 'room',
        locationIds: [],
        rosterLocationId: null,
        serviceIds: [],
        healthcareCode: null,
        jobRole: null
      },
      genderOptions: [
        { value: null, label: this.$t('global.unknown') },
        { value: 'female', label: this.$t('global.female') },
        { value: 'male', label: this.$t('global.male') }
      ],
      typeOptions: [
        { value: 'room', label: this.$t('global.items.room', 1) },
        { value: 'equipment', label: this.$t('global.items.equipment', 1) }
      ]
    };
  },
  apollo: {
    resource: {
      query: gql`
        query getResource($id: ID!) {
          resource(id: $id) {
            ...resource
            serviceIds
            locationIds
            customRoster
            rosterLocationId
          }
        }
        ${resourceFragment}
      `,
      variables() {
        return {
          id: this.resourceId
        };
      },
      skip() {
        return !this.resourceId;
      },
      result() {
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = this.resource[key];
        });
      }
    }
  },
  watch: {
    type: {
      handler(value) {
        if (value) {
          this.formData.type = value;
        }
      },
      immediate: true
    },
    'formData.rosterLocationId': {
      handler(value) {
        if (
          !this.formData.customRoster &&
          !this.formData.locationIds.includes(value)
        ) {
          this.formData.locationIds.push(value);
        }
      }
    },
    'formData.customRoster': {
      handler(value) {
        if (
          !value &&
          !this.formData.locationIds.includes(this.formData.rosterLocationId)
        ) {
          this.formData.locationIds.push(this.formData.rosterLocationId);
        }
      }
    },
    services: {
      handler(value) {
        if (value && !this.resourceId && this.isEmployee) {
          this.formData.serviceIds = value.map((service) => service.id);
        }
      },
      immediate: true,
      deep: true
    },
    locations: {
      handler(value) {
        if (value && !this.resourceId) {
          this.formData.locationIds = value.map((location) => location.id);
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useCompanyStore, [
      'company',
      'companySettings',
      'multiLocation',
      'isMarketLaunchCountry'
    ]),
    ...mapState(useResourcesStore, ['hasSingleEmployee']),
    ...mapState(useLocationsStore, ['locationId', 'locations']),
    ...mapState(useServicesStore, ['services']),
    resourceId() {
      return this.$route.params.id;
    },
    isEmployee() {
      return this.type === 'employee';
    },
    showTypeSelect() {
      return !this.isEmployee && !this.resourceId;
    },
    showPriceInfo() {
      return !this.resource && this.isEmployee && !this.isMarketLaunchCountry;
    },
    resourceType() {
      return this.isEmployee ? 'employee' : 'resource';
    },
    colorSettingChanged() {
      return (
        this.colorSetting &&
        this.colorSetting !== this.companySettings.agenda.colour
      );
    },
    disableDeselectServices() {
      // The user won't be able to deselect services when editing rooms and equipments
      return !this.isEmployee && !!this.resourceId;
    }
  },
  methods: {
    ...mapActions(useCompanyStore, ['updateCompany']),
    close() {
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName });
      } else {
        this.$emit('close');
      }
    },
    setPicture(picture) {
      this.formData.picture = picture?.public_id || '';
    },
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      if (this.multiLocation && !this.formData.locationIds.length) {
        this.showLocationIdsError = true;
        return;
      }

      this.isLoading = true;
      const input = { ...this.formData };
      input.type = this.formData.type?.toUpperCase() || 'EMPLOYEE';
      input.rosterLocationId = input.customRoster
        ? null
        : this.formData.rosterLocationId || this.locationId;

      if (!this.isEmployee) {
        delete input.gender;
      }

      if (this.resource) {
        input.id = this.resource.id;
        this.updateResource(input)
          .then(() => {
            this.updateColorSetting();
            flash(this.$t('global.flash.resource_updated'));
            this.$emit('updated', this.resource.id);
            this.close();
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.createResource(input)
          .then((resourceId) => {
            this.updateColorSetting();
            flash(this.$t('global.flash.resource_created'));
            this.addTracking();
            this.$emit('created', resourceId);
            this.close();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    addTracking() {
      if (!this.isEmployee) {
        this.mixpanel.track(`Resource created - ${this.formData.type}`);
      } else {
        this.mixpanel.track('Employee created');
      }
    },
    updateColorSetting() {
      if (this.colorSettingChanged) {
        this.updateCompany({
          settings: {
            agenda: {
              colour: this.colorSetting
            }
          }
        });
      }
    }
  },
  created() {
    if (!this.resourceId && this.isEmployee) {
      this.formData.customRoster = true;
    }
  }
});
</script>

<style lang="scss" module>
.sectionRight {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
