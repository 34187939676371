<template>
  <div :class="$style.base">
    <div :class="$style.header">
      <BaseHeading center size="m">
        {{ $t('store.products.header') }}
      </BaseHeading>
      <BaseHeading center :mt="0.5" size="m">
        {{ $t('store.products.sub_header') }}
      </BaseHeading>
    </div>
    <div :class="$style.logos">
      <img
        v-for="logo in logos"
        :key="logo"
        :src="`/img/store/products/${logo}.svg`"
        :class="$style.logo"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const logos = [
  'babybliss',
  'framar',
  'panasonic',
  'peggy',
  'wahl',
  'wella',
  'schwarzkopf'
];
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  padding: $spacing $spacing 4 * $spacing;
  gap: 2 * $spacing;
  justify-content: center;
  align-items: center;
}
.logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 2 * $spacing;
}

.logo {
  max-width: 140px;
}
</style>
