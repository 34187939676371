import type { ServiceCategory, ServiceGroup } from '@/types';

import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { useLazyQuery } from '@vue/apollo-composable';

import { GET_SERVICE_GROUPS } from './graphql';
import { useServicesStore } from '../services';
import { useServiceVariationGroups } from './actions';

export type SortOrder = {
  id: string;
  sortOrder: number;
};

export const useServiceGroupsStore = defineStore('serviceGroups', () => {
  const serviceGroups = ref<ServiceGroup[]>([]);

  const { serviceCategories } = storeToRefs(useServicesStore());

  const {
    createServiceGroup,
    updateServiceGroup,
    deleteServiceVariationGroup: deleteServiceVariationGroupAction,
    duplicateServiceVariationGroup: duplicateServiceVariationGroupAction,
    sortServiceVariationGroups
  } = useServiceVariationGroups();

  const {
    load: _getServiceGroups,
    onResult,
    loading: getServiceGroupsLoading,
    refetch: getServiceGroupsRefetch
  } = useLazyQuery(GET_SERVICE_GROUPS);

  onResult(({ data: { serviceGroups: result } }) => {
    serviceGroups.value = result;
  });

  const getServiceGroups = () => {
    if (serviceGroups.value.length) {
      serviceGroups.value = [];
      getServiceGroupsRefetch();
    } else {
      _getServiceGroups();
    }
  };

  const categoriesWithServices = computed(() =>
    serviceCategories.value.map((category: ServiceCategory) => ({
      ...category,
      services: serviceGroups.value.filter(
        (serviceGroup) => serviceGroup?.category?.id === category.id
      )
    }))
  );

  const appendServiceGroup = (serviceGroup: ServiceGroup) => {
    serviceGroups.value = [...serviceGroups.value, serviceGroup].sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
  };

  const getServiceGroupById = (id: string) =>
    serviceGroups.value.find((serviceGroup) => serviceGroup.id === id);

  const sortServiceGroups = (items: SortOrder[]) => {
    serviceGroups.value.forEach((serviceGroup, index) => {
      items.forEach(({ id, sortOrder }) => {
        if (serviceGroup.id === id) {
          serviceGroups.value[index].sortOrder = sortOrder;
        }
      });
    });

    serviceGroups.value = serviceGroups.value.sort(
      (a, b) => a.sortOrder - b.sortOrder
    );

    return sortServiceVariationGroups(items);
  };

  const deleteServiceVariationGroup = (id: number) =>
    deleteServiceVariationGroupAction(id).then(() => {
      serviceGroups.value = serviceGroups.value.filter(
        (serviceGroup) => serviceGroup.id !== id
      );
    });

  const duplicateServiceVariationGroup = (id: number) =>
    duplicateServiceVariationGroupAction(id).then(
      ({
        data: {
          duplicateServiceGroup: { serviceGroup }
        }
      }) => {
        appendServiceGroup(serviceGroup);
      }
    );

  return {
    serviceGroups,
    getServiceGroupsLoading,
    getServiceGroupsRefetch,
    categoriesWithServices,
    getServiceGroupById,
    getServiceGroups,
    createServiceGroup,
    updateServiceGroup,
    deleteServiceVariationGroup,
    duplicateServiceVariationGroup,
    sortServiceGroups
  };
});
