<template>
  <Modal v-bind="{ parentRouteName, saving }" @submit="submit">
    <div v-show="!loading" :class="$style.base">
      <BaseHeading size="l">{{
        $t('integrations.google_reserve.name')
      }}</BaseHeading>
      <BaseText>{{
        $t('integrations.google_reserve.settings.description')
      }}</BaseText>
      <BaseAlert
        :text="$t('integrations.google_reserve.settings.alert')"
        :primaryAction="{
          text: $t('global.learn_more'),
          href: $t('integrations.google_reserve.settings.help_article_url')
        }"
        mb
      />
      <BaseHeading>{{
        $t('integrations.google_reserve.settings.step_1')
      }}</BaseHeading>
      <div>
        <BaseText>{{
          $t('integrations.google_reserve.settings.step_1_description')
        }}</BaseText>
        <BaseText href="https://www.google.com/business/"
          >Google Business</BaseText
        >
      </div>

      <BaseHeading>{{
        $t('integrations.google_reserve.settings.step_2')
      }}</BaseHeading>
      <BaseText>{{
        $t('integrations.google_reserve.settings.step_2_description')
      }}</BaseText>
      <input
        ref="autocompleteInput"
        type="text"
        :class="$style.input"
        placeholder="Search for a business"
      />
      <div v-if="place">
        <Flex>
          <div :class="$style.detailsHeading">
            <BaseText bold
              >{{
                $t('integrations.google_reserve.settings.business_name')
              }}:</BaseText
            >
            <BaseText bold>Website:</BaseText>
            <BaseText bold>Google Maps:</BaseText>
            <BaseText bold
              >{{
                $t('integrations.google_reserve.settings.rating')
              }}:</BaseText
            >
          </div>
          <div :class="$style.details">
            <BaseText oneLine v-test="'place-name'">{{ place.name }}</BaseText>
            <BaseText :href="place.website" oneLine v-test="'place-website'">{{
              place.website
            }}</BaseText>
            <div>
              <BaseText :href="place.url" v-test="'place-url'"
                >Google Maps</BaseText
              >
            </div>
            <BaseText v-test="'place-rating'">{{ place.rating }}</BaseText>
          </div>
        </Flex>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { loadGoogleMaps } from './google-maps-loader';
import { useRouter } from 'vue-router';
import Modal from '../shared/Modal.vue';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { handledException } from '@/helpers/datadog';

const props = defineProps<{
  parentRouteName: string;
}>();

const GOOGLE_MAPS_API_KEY = 'AIzaSyA4Cr1oIVxDMnVPLmFcwCQVvM84TTGwt1s';
const autocompleteInput = ref(null);
const place = ref(null);
const apps = inject<any>('apps');
const defaultApps = inject<any>('defaultApps');
let autocomplete = null;

const initAutocomplete = async () => {
  try {
    const google = await loadGoogleMaps(GOOGLE_MAPS_API_KEY);

    if (google && autocompleteInput.value) {
      autocomplete = new google.maps.places.Autocomplete(
        autocompleteInput.value
      );
      autocomplete.setFields([
        'place_id',
        'name',
        'website',
        'url',
        'rating',
        'types'
      ]);
      autocomplete.addListener('place_changed', onPlaceChanged);
    }
  } catch (error) {
    handledException(new Error('Google Maps API failed to load'), {
      error
    });
  }
};

const onPlaceChanged = () => {
  const res = autocomplete.getPlace();

  place.value = {
    name: res.name,
    placeId: res.place_id,
    rating: res.rating,
    types: res.types,
    url: res.url,
    website: res.website
  };
};

onMounted(initAutocomplete);

const { onResult, loading } = useQuery(gql`
  query getPlaceInfo {
    placeInfo {
      name
      placeId
      rating
      types
      url
      website
    }
  }
`);

onResult(({ data }) => {
  place.value = data.placeInfo;
});

const { t } = useI18n();

const router = useRouter();
const saving = ref(false);

const { mutate } = useMutation(gql`
  mutation createGoogleReserveApp($input: CreateGoogleReserveAppInput!) {
    createGoogleReserveApp(input: $input) {
      googleReserveApp {
        id
      }
    }
  }
`);

const submit = () => {
  saving.value = true;

  mutate({
    input: {
      name: place.value.name,
      rating: String(place.value.rating),
      url: place.value.url,
      website: place.value.website,
      types: place.value.types,
      placeId: place.value.placeId
    }
  })
    .then(() => {
      const googleReserveApp = defaultApps.value.find(
        (app) => app.key === 'google_reserve'
      );
      googleReserveApp.enabled = true;
      apps.value.push(googleReserveApp);

      flash(t('global.flash.integration_updated'));
      router.push({ name: props.parentRouteName });
    })
    .finally(() => {
      saving.value = false;
    });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  gap: $spacing;
}

.input {
  @include input;
}

.detailsHeading {
  flex-shrink: 0;
}

.details {
  overflow: hidden;
}
</style>
