<template>
  <div>
    <InputElement
      v-model="value"
      type="textarea"
      v-bind="{ uid, hasError, placeholder, focus, rows, disabled, size }"
    />
  </div>
</template>

<script lang="ts">
import InputElement from './_shared/InputElement.vue';
import props from './_shared/props';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    InputElement
  },
  inheritAttrs: false,
  props: {
    modelValue: String,
    type: String,
    ...props()
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
});
</script>
