import config from '@/config';

const LoggedOutContainer = () => import('@/app/logged-out/index.vue');

const Login = () => import('@/modules/auth/Login.vue');
const LoggedOut = () => import('@/modules/auth/LoggedOut.vue');
const NewPassword = () => import('@/modules/auth/NewPassword.vue');
const ResetPassword = () => import('@/modules/auth/ResetPassword.vue');
const Signup = () => import('@/modules/auth/Signup.vue');
const Verification = () => import('@/modules/auth/verification/index.vue');
const TwoFactorAuthentication = () =>
  import('@/modules/auth/two-factor-authentication/index.vue');

export default [
  {
    path: `/:locale(${config.locales.join('|')})?`,
    component: LoggedOutContainer,
    name: 'outside-app',
    children: [
      {
        path: 'signin',
        name: 'login',
        component: Login
      },
      {
        path: 'signup',
        name: 'signup',
        component: Signup
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: NewPassword
      },
      {
        path: 'new-password',
        name: 'new-password',
        component: ResetPassword
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPassword
      },
      {
        path: 'signed-out',
        name: 'logged-out',
        component: LoggedOut
      },
      {
        path: 'signout',
        name: 'logout'
      },
      {
        path: 'verification',
        name: 'verification',
        component: Verification
      },
      {
        path: 'two-factor-auth',
        name: 'two-factor-auth',
        component: TwoFactorAuthentication
      }
    ]
  }
];
