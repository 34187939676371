import { defineStore, acceptHMRUpdate, storeToRefs } from 'pinia';
import { reactive, computed, ref, watch } from 'vue';
import { useLocationsStore } from '@/stores/locations';
import { useResourcesStore } from '@/stores/resources';
import { useRouter } from 'vue-router';

import type {
  OrderItemAttributes,
  CreateOrderInput,
  Appointment,
  AppointmentPart,
  PrepaidCardAttributes,
  Customer,
  TransactionAttributes,
  OrderPermissions,
  OrderState,
  PrepaidCard
} from '@/types';

export type OrderItem = OrderItemAttributes & {
  _uid: number;
  id?: string;
  originalPrice: number;
  discount: number;
  appointmentId?: number;
  prepaidCard?: PrepaidCardAttributes & {
    visits: number;
  };
  transactionCost?: boolean;
  vatRateId?: number;
};

export type OrderTransaction = TransactionAttributes & {
  _uid: number;
  id?: string;
  name?: string;
  amount: number;
  deletable: boolean;
  prepaidCard?: PrepaidCard;
};

export type Order = CreateOrderInput & {
  id?: number;
  items: OrderItem[];
  transactions: OrderTransaction[];
  permissions?: OrderPermissions;
  state?: OrderState;
  cashupId?: number | null;
  fiscalizationFinished: boolean;
  number?: string;
};

let uid = 1;
let transaction_uid = 1;

export const useRegisterOrderStore = defineStore('register/order', () => {
  const { locationId } = storeToRefs(useLocationsStore());
  const { employees, hasSingleEmployee } = storeToRefs(useResourcesStore());

  const initialState = (): Order => ({
    id: undefined,
    invoicedAt: undefined,
    items: [],
    locationId: locationId.value,
    transactions: [],
    note: '',
    customerId: null,
    originalOrderId: null,
    draft: false,
    fiscalizationFinished: false,
    permissions: {
      credit: true,
      delete: true,
      edit: true,
      editOrderItems: true
    },
    cashupId: null,
    pending: false,
    number: ''
  });

  const order = reactive<Order>({ ...initialState() });

  const totalPrice = computed(() =>
    order.items.reduce(
      (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
      0
    )
  );
  const totalPaid = computed(() =>
    order.transactions.reduce(
      (acc, transaction) => acc + (transaction.amount || 0),
      0
    )
  );
  const outstandingAmount = computed(() => totalPrice.value - totalPaid.value);

  const itemResource = computed(() =>
    hasSingleEmployee.value
      ? employees.value[0].id
      : selectedEmployeeId.value !== 'multiple'
        ? selectedEmployeeId.value
        : null
  );

  const addItem = ({
    id,
    medical = false,
    name,
    price,
    productId,
    serviceId,
    giftcard,
    prepaidCard,
    appointmentPartId,
    appointmentId,
    discount = 0,
    discountCodeId,
    loyaltyPointsAmount = 0,
    originalPrice = price,
    quantity = 1,
    employeeId,
    transactionCost = false,
    vatRateId,
    usedPrepaidCardId,
    medicalReferenceNumber = ''
  }: {
    id?: string;
    medical?: boolean;
    name: string;
    price: number;
    productId?: number;
    serviceId?: number;
    giftcard?: any;
    prepaidCard?: any;
    appointmentPartId?: number;
    appointmentId?: number;
    discount?: number;
    discountCodeId?: string;
    loyaltyPointsAmount?: number;
    originalPrice?: number;
    quantity?: number;
    employeeId?: number;
    transactionCost?: boolean;
    vatRateId?: number;
    usedPrepaidCardId?: number;
    medicalReferenceNumber?: string;
  }) => {
    // Add a new order item. If an item with the same service/product id already exists, increase its quantity EXCEPT if item is appointment
    const existingItem = appointmentPartId
      ? null
      : order.items.find(
          (orderItem: OrderItem) =>
            ((orderItem.serviceId &&
              serviceId &&
              orderItem.serviceId === serviceId) ||
              (orderItem.productId && orderItem.productId === productId)) &&
            orderItem.price === price
        );

    if (!id && existingItem?.quantity) {
      existingItem.quantity = existingItem.quantity + quantity;
    } else {
      order.items.push({
        _uid: uid,
        id,
        medical,
        name,
        price: price || 0,
        productId,
        quantity,
        serviceId,
        giftcard,
        prepaidCard,
        appointmentPartId,
        appointmentId,
        discount: originalPrice
          ? discount || 100 - (price * 100) / originalPrice || 0
          : 0,
        discountCodeId,
        originalPrice: originalPrice || 0,
        loyaltyPointsAmount,
        employeeId: transactionCost ? null : employeeId || itemResource.value,
        transactionCost,
        vatRateId,
        usedPrepaidCardId,
        medicalReferenceNumber
      });
    }

    uid++;
  };

  const removeItem = (uid: number) => {
    const itemToRemove = order.items.find(
      (item: OrderItem) => item._uid === uid
    );
    if (itemToRemove?.id) {
      deletedItems.value.push({ ...itemToRemove, destroy: true });
    }
    order.items = order.items.filter((item: OrderItem) => item._uid !== uid);
  };

  const duplicateItem = ({ _uid, price }: { _uid: number; price: number }) => {
    const item: OrderItem | undefined = order.items.find(
      (item: OrderItem) => item._uid === _uid
    );
    const idx = order.items.findIndex((item: OrderItem) => item._uid === _uid);

    if (item) {
      order.items.splice(idx + 1, 0, {
        ...item,
        id: undefined,
        _uid: uid,
        price,
        quantity: 1
      });

      uid++;
    }

    uid++;
  };

  const selectedEmployeeId = ref<'multiple' | number | null | undefined>(null);

  const router = useRouter();

  watch(selectedEmployeeId, () => {
    if (router?.currentRoute.value.name === 'register-payment') {
      return;
    }

    order.items.forEach((item: OrderItem) => {
      if (
        !item.transactionCost &&
        (selectedEmployeeId.value !== 'multiple' || !item.employeeId)
      ) {
        item.employeeId = itemResource.value;
      }
    });
  });

  const orderAppointments = ref<Appointment[]>([]);

  const selectedCustomer = ref<Customer | null>(null);

  watch(
    () => selectedCustomer.value?.id,
    (customerId) => {
      if (customerId) {
        order.customerId = customerId;
      } else {
        order.customerId = null;

        order.items.forEach((item: OrderItem) => {
          item.loyaltyPointsAmount = 0;
          item.usedPrepaidCardId = null;
        });

        order.transactions = order.transactions.filter(
          (transaction: OrderTransaction) => !transaction.prepaidCardId
        );

        selectedCustomer.value = null;
      }
    }
  );

  watch(
    () => order.customerId,
    (customerId) => {
      if (!customerId) {
        selectedCustomer.value = null;
      }
    }
  );

  const deletedItems = ref<OrderItem[]>([]);
  const deletedTransactions = ref<OrderTransaction[]>([]);

  // Reset the state
  const reset = () => {
    Object.assign(order, initialState());
    selectedEmployeeId.value = null;
    selectedCustomer.value = null;
    orderAppointments.value = [];
    deletedItems.value = [];
    deletedTransactions.value = [];
  };

  const removeOrderAppointment = (appointmentId: number) => {
    const appointment = orderAppointments.value.find(
      (orderAppointment: Appointment) => orderAppointment.id === appointmentId
    );
    orderAppointments.value = orderAppointments.value.filter(
      (orderAppointment: Appointment) => orderAppointment.id !== appointmentId
    );
    const appointmentPartIds =
      appointment?.parts.map((part: AppointmentPart) => part.id) || [];
    const orderItemsToRemove = order.items.filter(
      (item: OrderItem) =>
        (item.appointmentId === appointmentId && item.transactionCost) ||
        appointmentPartIds.includes(item.appointmentPartId as number)
    );
    const transactionsToRemove = order.transactions.filter(
      (transaction: OrderTransaction) =>
        // remove transactions that are not deletable, otherwise customers wont be able to fix it themselves if they get it wrong
        transaction.type === 'TREATWELL_ONLINE_PAYMENT' ||
        transaction.type === 'ONLINE' ||
        transaction.type === 'POS'
    );

    orderItemsToRemove.forEach((item: OrderItem) => {
      removeItem(item._uid);
    });
    transactionsToRemove.forEach((transaction: OrderTransaction) => {
      removeTransaction(transaction);
    });
  };

  const addTransaction = ({
    id,
    type,
    amount,
    giftcardId,
    name,
    prepaidCardId,
    deletable = true,
    transactionAt,
    prepaidCard
  }: {
    id?: string;
    type: string;
    amount: number;
    giftcardId?: string;
    name?: string;
    prepaidCardId?: string;
    deletable?: boolean;
    transactionAt?: string;
    prepaidCard?: PrepaidCard;
  }) => {
    const existingPrepaidCardTransaction = order.transactions.find(
      (transaction: OrderTransaction) =>
        transaction.prepaidCardId && transaction.prepaidCardId === prepaidCardId
    );
    if (existingPrepaidCardTransaction) {
      existingPrepaidCardTransaction.amount += amount;
    } else {
      order.transactions.push({
        id,
        type: type as any,
        amount,
        giftcardId,
        prepaidCard,
        prepaidCardId,
        name,
        locationId: locationId.value,
        transactionAt,
        deletable,
        _uid: transaction_uid
      });

      transaction_uid++;
    }
  };

  const removeTransaction = (transaction: OrderTransaction) => {
    const transactionToRemove = order.transactions.find(
      (orderTransaction: OrderTransaction) =>
        orderTransaction._uid === transaction._uid
    );

    // reset the items prepaid card to null after the prepaid card is removed & set remaining about back
    if (transaction.type === 'PREPAID_CARD') {
      let itemsQuantity = 0;
      order.items.forEach((item: OrderItem) => {
        if (
          Number(item.usedPrepaidCardId) ===
          parseInt(transaction.prepaidCardId as string)
        ) {
          item.usedPrepaidCardId = null;
          item.price = item.originalPrice;
          itemsQuantity += item.quantity || 1;
        }
      });
      if (transactionToRemove && transactionToRemove.prepaidCard) {
        transactionToRemove.prepaidCard.remaining += itemsQuantity;
      }
    }

    if (transactionToRemove?.id) {
      deletedTransactions.value.push({ ...transactionToRemove, destroy: true });
    }
    order.transactions = order.transactions.filter(
      (orderTransaction: OrderTransaction) =>
        orderTransaction._uid !== transaction._uid
    );
  };

  const removeAllTransactions = (transaction: OrderTransaction) => {
    order.transactions.forEach((orderTransaction: OrderTransaction) => {
      if (orderTransaction.id && orderTransaction.type === transaction.type) {
        deletedTransactions.value.push({ ...orderTransaction, destroy: true });
      }
    });

    order.transactions = order.transactions.filter(
      (orderTransaction: OrderTransaction) =>
        orderTransaction.type !== transaction.type
    );
  };

  const setAppointmentResource = (resourceId: number) => {
    if (!selectedEmployeeId.value || !order.items.length) {
      selectedEmployeeId.value = resourceId;
    }
  };

  const totalTwPayments = computed(() =>
    orderAppointments.value
      .filter((appointment) => appointment.paidThroughTreatwell)
      .reduce(
        (sum, appointment) =>
          sum + (appointment.pricePaidThroughTreatwell || 0),
        0
      )
  );

  watch(
    () => locationId.value,
    () => {
      order.locationId = locationId.value;
      selectedEmployeeId.value = null;
    }
  );

  const isEmpty = computed(
    () => !order.items.length && !order.note && !orderAppointments.value.length
  );

  return {
    order,
    totalPrice,
    addItem,
    removeItem,
    reset,
    duplicateItem,
    selectedEmployeeId,
    selectedCustomer,
    orderAppointments,
    removeOrderAppointment,
    addTransaction,
    totalPaid,
    outstandingAmount,
    removeTransaction,
    removeAllTransactions,
    setAppointmentResource,
    totalTwPayments,
    deletedItems,
    deletedTransactions,
    isEmpty
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useRegisterOrderStore, import.meta.hot)
  );
}
