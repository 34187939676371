<template>
  <div
    :class="[
      $style.base,
      {
        [$style.expandable]: canExpand,
        [$style.expanded]: expanded,
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'register-order-item'"
  >
    <BaseCard noPadding>
      <div v-test="'register-order-item-text'">
        <div :class="$style.top">
          <div v-if="canExpand" :class="$style.arrow" @click="setExpand">
            <div :class="$style.arrowInner">
              <BaseIcon name="chevron-down" color="primary" size="l" />
            </div>
          </div>
          <div :class="$style.headers">
            <div v-if="itemIsCardType" :class="$style.quantityInput">
              <BaseInput
                v-model="item.quantity"
                type="number"
                controls
                v-test="'register-order-item-quantity'"
              />
            </div>
            <div :class="$style.inner" @click="setExpand">
              <div v-if="item.giftcard">
                {{
                  `${$t('global.payments.giftcard')} (${item.giftcard.code})`
                }}
              </div>
              <div v-else-if="item.prepaidCard">
                {{
                  `${$t('global.payments.prepaid_card')}: ${item.prepaidCard.description} (${item.prepaidCard.visits}x)`
                }}
              </div>
              <div v-else-if="item.transactionCost">
                {{ item.name }}
              </div>
              <div v-else>
                {{ item.name }}
              </div>

              <div :class="$style.price">
                <BaseIcon
                  v-if="adjustedPrice"
                  name="info"
                  :tooltip="{
                    text: $t('register.price_adjusted'),
                    touch: true
                  }"
                  :mr="0.5"
                />
                <BaseText
                  v-if="
                    item.originalPrice !== 0 &&
                    item.discount !== 0 &&
                    item.originalPrice !== item.price
                  "
                  lineThrough
                  inline
                  :mr="0.5"
                  v-test="'register-order-item-top-price-original'"
                >
                  {{ filters.currency(item.quantity! * item.originalPrice!) }}
                </BaseText>
                <div v-test="'register-order-item-top-price'">
                  {{ filters.currency(item.price! * item.quantity!) }}
                </div>
              </div>
            </div>
          </div>
          <div :class="$style.actions">
            <BaseMore
              :options="options"
              inline
              size="s"
              @select="onSelectAction"
              v-test="'register-order-item-options'"
            />
          </div>
        </div>
      </div>
      <div v-if="canExpand" v-show="expanded" :class="$style.bottom">
        <div v-if="itemIsCardType" :class="$style.input">
          <BaseInput
            v-model="item.price"
            :label="$t('global.price')"
            type="currency"
            v-test="'register-order-item-price'"
          />
        </div>
        <div v-if="itemIsCardType" :class="[$style.input, $style.inputLarge]">
          <OrderItemDiscount :item="item" />
        </div>
        <div v-if="canSetLoyaltyPoints" :class="$style.input">
          <BaseInput
            v-model="item.loyaltyPointsAmount"
            :label="$t('register.redeem_loyalty_points')"
            type="number"
            :minValue="0"
            :maxValue="selectedCustomer?.loyaltyPoints || 0"
            v-test="'register-order-item-loyalty-points'"
          />
        </div>
        <div
          v-show="
            !item.transactionCost && (multipleEmployees ?? itemIsCardType)
          "
          :class="$style.input"
        >
          <BaseDropdown
            v-model="item.employeeId"
            :options="
              employees.map((employee) => ({
                value: employee.id,
                label: employee.name
              }))
            "
            :label="$t('global.items.employee', 1)"
            resources
            :required="
              !item.transactionCost && (multipleEmployees ?? itemIsCardType)
            "
            v-test="'register-order-item-employee'"
          />
        </div>
        <div v-if="company.medical && item.medical && itemIsCardType">
          <BaseInput
            v-model="item.medicalReferenceNumber"
            :label="$t('register.medical_reference_number')"
            v-test="'register-order-item-medical-description'"
          />
        </div>
        <div v-if="company.medical && itemIsCardType" :class="$style.medical">
          <BaseCheckbox
            v-model="item.medical"
            :label="$t('global.medical')"
            v-test="'register-order-item-medical'"
          />
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';
import type { Appointment } from '@/types';
import type { OrderItem } from '@/modules/register/stores/order';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useServicesStore } from '@/stores/services';
import OrderItemDiscount from './OrderItemDiscount.vue';

const { t } = useI18n();

const props = defineProps<{
  item: OrderItem;
  index: number;
}>();

const { order, removeItem, duplicateItem, removeOrderAppointment } =
  useRegisterOrderStore();
const { orderAppointments } = storeToRefs(useRegisterOrderStore());
const { selectedEmployeeId, selectedCustomer } = storeToRefs(
  useRegisterOrderStore()
);
const { employees } = storeToRefs(useResourcesStore());
const { company, isLoyaltyPointsEnabled } = useCompanyStore();
const { services } = useServicesStore();

const adjustedPrice = ref(false);

const orderLength = computed(() => order.items.length);

const expanded = ref(props.index === orderLength.value - 1);

const itemIsCardType = computed(
  () =>
    !props.item.prepaidCard &&
    !props.item.giftcard &&
    !props.item.transactionCost
);
const multipleEmployees = computed(
  () => selectedEmployeeId.value === 'multiple'
);
const canSetLoyaltyPoints = computed(
  () =>
    order.customerId &&
    isLoyaltyPointsEnabled &&
    itemIsCardType.value &&
    (!order.state || !['PAID', 'OUTSTANDING'].includes(order.state))
);

const options = computed(() => {
  if (props.item.giftcard || props.item.transactionCost) {
    return ['delete'];
  }

  return ['duplicate', 'delete'];
});

const onSelectAction = (action: string) => {
  switch (action) {
    case 'delete':
      checkConnectedAppointment();
      removeItem(props.item._uid);
      break;
    case 'edit':
      if (props.item.prepaidCard || props.item.giftcard) {
        return;
      }

      expanded.value = !expanded.value;
      break;
    case 'duplicate':
      duplicateItem({ _uid: props.item._uid, price: props.item.price || 0 });
      break;
  }
};

const checkConnectedAppointment = () => {
  let lastAppointmentPart;
  let orderAppointment: Appointment | undefined;
  if (props.item.appointmentId) {
    orderAppointment = orderAppointments.value.find(
      (appointment: Appointment) => appointment.id === props.item.appointmentId
    );
    if (orderAppointment) {
      lastAppointmentPart =
        order.items.filter(
          (item: OrderItem) => item.appointmentId === item.appointmentId
        ).length === 1;
    }
  }

  if (lastAppointmentPart) {
    modal('confirmation', {
      type: 'delete',
      item: t('global.items.appointment')
    }).then(() => {
      removeOrderAppointment(orderAppointment?.id as number);
    });
  }
};

const canExpand = computed(() => {
  if (
    (props.item.giftcard ||
      props.item.prepaidCard ||
      props.item.transactionCost) &&
    !multipleEmployees.value
  ) {
    return false;
  } else {
    return true;
  }
});

const setExpand = () => {
  if (canExpand.value) {
    expanded.value = !expanded.value;
  }
};

const service = services.find(
  (service: any) => service.id === props.item.serviceId
);

watch(
  () => props.item.employeeId,
  (employeeId) => {
    const serviceHasAdjustments = !!service?.resourceAdjustments?.length;
    const adjustment = service?.resourceAdjustments?.find(
      (adjustment: any) => adjustment.resourceId === employeeId
    );

    if (adjustment) {
      adjustedPrice.value = true;
      props.item.price = adjustment.price;
    } else if (serviceHasAdjustments) {
      adjustedPrice.value = false;
      props.item.price = props.item.originalPrice;
    }
  },
  {
    immediate: true
  }
);

watch(selectedEmployeeId, () => {
  if (selectedEmployeeId.value === 'multiple' && canExpand.value) {
    expanded.value = true;
  }
});

watch(
  orderLength,
  () => {
    if (props.index < orderLength.value - 1) {
      expanded.value = false;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" module>
.base {
  position: relative;
}

$arrowWidth: 36px;

.top {
  display: flex;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $arrowWidth;
  flex-shrink: 0;
}

.arrowInner {
  .base:not(.expanded) & {
    transform: rotate(-90deg);
  }
}

.quantityInput {
  padding: $spacing 0;
}

.inner,
.arrow {
  .base.expandable & {
    cursor: pointer;
  }
}

.headers,
.inner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.inner {
  padding-left: $spacing;
  height: 100%;
  gap: $spacing * 0.5;
  padding-top: $spacing * 0.5;
  padding-bottom: $spacing * 0.5;

  .base.smallScreen:not(.expanded) & {
    padding-right: $spacing;
  }
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: $spacing;
  padding-bottom: $spacing;

  .base:not(.smallScreen) & {
    padding-left: $arrowWidth;
  }

  .base.smallScreen & {
    padding-left: $spacing;
    padding-right: $spacing;
  }
}

.input {
  display: flex;
  align-items: flex-end;
  width: 130px;
  max-width: 130px;
  min-width: 90px;

  &.inputLarge {
    width: 180px;
    max-width: 180px;
  }

  & > * {
    width: 100%;
  }
}

.quantityInput {
  width: 75px;
  flex-shrink: 0;
}

.price {
  display: flex;
  align-items: center;

  .base.smallScreen.expanded & {
    display: none;
  }
}

.medical {
  align-self: center;
}

.actions {
  display: flex;
  align-items: center;

  .base.smallScreen:not(.expanded) & {
    display: none;
  }
}
</style>
