<template>
  <div>
    <BaseGrid container>
      <BaseGrid :size="7">
        <BaseHeading :mb="0.25">
          {{ title }}
        </BaseHeading>
        <BaseText>
          {{ description }}
        </BaseText>
      </BaseGrid>
      <BaseGrid alignRight alignCenter>
        <BaseButton
          color="inverted"
          @click="goToRoute"
          v-test="`visit-${buttonRoute}`"
        >
          {{ buttonText }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts" setup>
import { modal } from '@/helpers/ui';
import { inject, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useContactEmailVerification } from '../useContactEmailVerification';
import { useI18n } from 'vue-i18n';

const mixpanel = inject<any>('mixpanel');
const router = useRouter();

const props = defineProps<{
  title: string;
  description: string;
  buttonText: string;
  buttonRoute: string;
  mixpanelEvent?: string;
}>();

const { t } = useI18n();
const { contactEmailVerified, verifyContactEmail } =
  useContactEmailVerification();

const showVerificationModal = () => {
  modal('confirmation', {
    type: 'sendEmail',
    subMessage: t('email_templates.verification_modal.sub_message'),
    message: t('email_templates.verification_modal.message')
  }).then(() => {
    verifyContactEmail();
  });
};

const goToRoute = () => {
  if (!contactEmailVerified) {
    showVerificationModal();
    return;
  }

  router.push({ name: props.buttonRoute });
  if (props.mixpanelEvent) {
    mixpanel.track(props.mixpanelEvent);
  }
};
</script>
