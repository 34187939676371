<template>
  <ModuleBase
    :navItems="[
      {
        text: $t('global.pages.products'),
        name: 'products-all'
      },
      {
        text: $t('global.pages.categories'),
        name: 'products-categories'
      },
      {
        text: $t('global.pages.suppliers'),
        name: 'products-suppliers'
      },
      {
        text: $t('global.pages.stock_orders'),
        name: 'products-stock-orders'
      },
      {
        text: $t('global.pages.product_imports'),
        name: 'product-imports'
      }
    ]"
    :fullHeight="!hasFeatureFlag('module-products')"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import dayjs from '@/dayjs';

const userStore = useUserStore();
const hasFeatureFlag = computed(() => userStore.hasFeatureFlag);

const { updateCompany } = useCompanyStore();
updateCompany({
  settings: {
    store: {
      lastVisitedProductsAreaAt: dayjs().format()
    }
  }
});
</script>
