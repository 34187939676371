<template>
  <ModuleBase noPadding :fullHeight="showStore">
    <Store v-if="showStore" />
    <Landing v-else />
  </ModuleBase>
</template>
<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import Landing from './landing/index.vue';
import Store from './store/index.vue';
import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';

const showStore = computed(() => {
  const { companySettings } = useCompanyStore();
  return (
    !!companySettings.store.interestedAt &&
    unleash.isEnabled('TreatwellStoreStore')
  );
});
</script>
