<template>
  <div :class="$style.base">
    <RouterLink
      v-if="showReferralProgram"
      :to="{ name: 'admin-referrals' }"
      :active-class="$style.isActive"
      :class="$style.link"
      @click="close"
    >
      <BaseIcon name="person-add" color="inverted" />
      <BaseText :ml="0.5" color="inverted">{{
        t('nav.invite_colleagues')
      }}</BaseText>
    </RouterLink>
    <div :class="$style.link" @click="logout">
      <BaseIcon name="logout" color="inverted" />
      <BaseText :ml="0.5" color="inverted">{{ t('nav.logout') }}</BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { logout } from '@/helpers/session';

const { t } = useI18n();
import { usePageLayoutStore } from '@/stores/page-layout';
import { useCompanyStore } from '@/stores/company';
const { showMobileMenu } = storeToRefs(usePageLayoutStore());
const { showReferralProgram } = useCompanyStore();

const close = () => (showMobileMenu.value = false);
</script>
<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: flex-end;
}
.link {
  display: flex;
}
</style>
