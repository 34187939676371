<template>
  <BaseCard>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
      v-test="'app-card'"
    >
      <div :class="$style.top">
        <div :class="$style.headerWrapper">
          <div :class="$style.header">
            <img v-if="app.visual" :src="app.visual" :class="$style.visual" />
            <BaseHeading>
              {{ app.heading }}
            </BaseHeading>
          </div>
          <BaseLabel v-if="app.enabled" state="enabled" />
        </div>
        <BaseText>
          {{ app.description }}
        </BaseText>
      </div>
      <div :class="$style.bottom">
        <BaseText
          v-if="app.readMoreLink"
          :routerLink="{
            name: app.readMoreLink
          }"
          v-test="'app-readmore'"
        >
          {{ $t('integrations.treatwell_marketplace.read_more') }}
        </BaseText>
        <BaseButton
          v-else-if="app.enabled && app.settingsRoute"
          color="inverted"
          :routerLink="app.settingsRoute"
          v-test="'app-settings'"
        >
          {{ $t('global.settings') }}
        </BaseButton>
        <BaseButton
          v-else-if="!app.enabled && !app.disableEnable"
          color="inverted"
          :routerLink="app.enableRoute"
          @click="$emit('enable')"
          v-test="'app-enable'"
        >
          {{ $t('global.actions.enable') }}
        </BaseButton>
        <div
          v-if="app.enabled && !app.disableUnavailable"
          :class="$style.toggle"
        >
          <BaseIcon
            name="delete"
            color="error"
            clickable
            @click="disableApp"
            v-test="'app-disable'"
          />
        </div>
      </div>
      <BaseAlert
        v-if="app.enabled && app.alert"
        :text="app.alert"
        v-test="'app-alert'"
      />
      <BaseAlert
        v-else-if="!app.enabled && app.disabledAlert"
        :text="app.disabledAlert"
        v-test="'app-alert'"
      />
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable';
import type { App } from './types';
import { DISABLE_APP } from './graphql';
import { flash, modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
const props = defineProps<{
  app: App;
}>();

defineEmits(['enable']);
const { t } = useI18n();
const { mutate } = useMutation(DISABLE_APP);
const { user } = useUserStore();
const showTreatwellModal = ref(false);
const disableApp = () => {
  if (props.app.key === 'treatwell_marketplace' && !user?.salonizedAdmin) {
    modal('warning', {
      message: t('integrations.treatwell.modal.heading'),
      subMessage: t('integrations.treatwell.modal.description')
    }).then(() => {
      showTreatwellModal.value = false;
    });
    return;
  }
  modal('confirmation', {
    type: 'disable',
    message: t('integrations.disable_confirmation_modal.heading', {
      app: props.app.heading
    }),
    subMessage: t('integrations.disable_confirmation_modal.description', {
      app: props.app.heading
    }),
    warning: t('integrations.disable_confirmation_modal.warning', {
      app: props.app.heading
    })
  }).then(() => {
    mutate({
      input: {
        appName: props.app.key
      }
    }).then(() => {
      props.app.enabled = false;
      flash(t('global.flash.integration_disabled'));
    });
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(.smallScreen) {
    min-height: 150px;
  }
}

.top {
  margin-bottom: $spacing;
}

.header,
.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: $spacing * 0.5;
  gap: 10px;
}
.headerWrapper {
  align-items: start;
  justify-content: space-between;
}

.visual {
  max-width: 36px;
  max-height: 36px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing;
  height: 42px;

  &:empty {
    display: none;
  }
}

.toggle {
  margin-left: auto;
}
</style>
