import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed } from 'vue';
import { OS } from '@/user-context';

export const useNativeAppStore = defineStore('nativeApp', () => {
  const isNativeApp = ref(document.body.classList.contains('is-native-app'));
  const isNativeAppIOS = computed(() => isNativeApp.value && OS === 'iOS');

  return {
    isNativeApp,
    isNativeAppIOS
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNativeAppStore, import.meta.hot));
}
