<template>
  <ModuleBase noBorder gray>
    <PageWrap
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
      :heading="$t('admin.nav.links.company')"
    >
      <BaseCard :mb="2">
        <BaseInput
          v-model="formData.name"
          :label="$t('company.name')"
          required
          :info="
            hasFeatureFlag('franchise-scope') ? $t('company.name_info') : ''
          "
          :minLength="2"
          :maxLength="255"
          mb
          v-test="'company-settings-name'"
        />
        <BaseInput
          v-if="hasFeatureFlag('franchise-scope')"
          v-model="formData.internalName"
          :label="$t('company.internal_name')"
          required
          :info="$t('company.internal_name_info')"
          :minLength="2"
          :maxLength="255"
          mb
          v-test="'company-settings-internalName'"
        />
        <BaseInput
          v-model="formData.address"
          :label="$t('company.address')"
          mb
          v-test="'company-settings-address'"
        />
        <div :class="[$style.formRow, $style.mobileFull]">
          <BaseInput
            v-model="formData.postalcode"
            :label="$t('company.postal_code')"
            mb
            v-test="'company-settings-postalcode'"
          />
          <BaseInput
            v-model="formData.city"
            :label="$t('company.city')"
            mb
            v-test="'company-settings-city'"
          />
        </div>
        <BaseInput
          v-model="formData.phone"
          :label="$t('company.phone_number')"
          type="tel"
          :regex="COMPANY_PHONE_VALIDATION_REGEX"
          mb
          v-test="'company-settings-phone'"
        />
        <BaseInputLabel
          :text="t('company.logo')"
          :info="t('company.info_image_autoresize')"
          v-test="'_form-element-label'"
        />
        <div :class="[$style.row]">
          <BaseImage
            v-if="!!currentLogoImageUrl"
            :cloudinaryId="currentLogoImageUrl"
            :width="120"
            :height="120"
          />
          <svg
            v-if="!currentLogoImageUrl"
            :class="$style.logoPlaceholder"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill="#231F20"
              fill-rule="evenodd"
              d="M8 10a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 8 10Zm10 9H6.561l7.005-5.845c.246-.209.692-.208.933-.001L19 16.994V18a1 1 0 0 1-1 1ZM6 5h12a1 1 0 0 1 1 1v8.364l-3.203-2.732c-.99-.842-2.539-.842-3.52-.006L5 17.698V6a1 1 0 0 1 1-1Zm12-2H6C4.346 3 3 4.346 3 6v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3Z"
              clip-rule="evenodd"
            />
            <mask
              id="a"
              width="18"
              height="18"
              x="3"
              y="3"
              maskUnits="userSpaceOnUse"
              style="mask-type: luminance"
            >
              <path
                fill="#fff"
                fill-rule="evenodd"
                d="M8 10a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 8 10Zm10 9H6.561l7.005-5.845c.246-.209.692-.208.933-.001L19 16.994V18a1 1 0 0 1-1 1ZM6 5h12a1 1 0 0 1 1 1v8.364l-3.203-2.732c-.99-.842-2.539-.842-3.52-.006L5 17.698V6a1 1 0 0 1 1-1Zm12-2H6C4.346 3 3 4.346 3 6v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3Z"
                clip-rule="evenodd"
              />
            </mask>
            <g mask="url(#a)"><path d="M0 0h24v24H0z" /></g>
          </svg>
          <BaseUpload
            preset="company_logo"
            :hasImage="!!currentLogoImageUrl"
            @uploadResults="handleUpload"
          />
          <BaseButton
            v-if="!!currentLogoImageUrl"
            color="inverted"
            @click="
              () => {
                formData.logoUrl = null;
                currentLogoImageUrl = undefined;
              }
            "
          >
            {{ t('global.actions.delete') }}
          </BaseButton>
        </div>
        <VatIdSetting v-if="company.activeSubscription" />
        <BaseInput
          v-if="company.country === 'nl'"
          v-model="formData.rsinNumber"
          :label="$t('company.rsin_number')"
          :regex="RSIN_NUMBER_VALIDATION_REGEX"
          mb
          mt
          v-test="'company-settings-rsinNumber'"
        />
      </BaseCard>

      <BaseCard :heading="t('company.email_settings')" :mb="2">
        <BaseInput
          v-model="formData.notificationEmail"
          :label="$t('company.notification_email_address')"
          :info="$t('company.notification_email_address_info')"
          type="email"
          required
          mb
          v-test="'company-settings-notificationEmail'"
        />
        <BaseSpinner v-if="loading" inline />
        <BaseInput
          v-else
          v-model="formData.billingInfo.billingEmail"
          :label="$t('company.billing_email_address')"
          :info="$t('company.billing_email_address_info')"
          type="email"
          mb
          v-test="'company-settings-billingEmail'"
        />
        <div v-if="unleash.isEnabled('AmazonSES-CustomDomain')">
          <div>
            <BaseInputLabel :text="$t('company.contact_email')" />
          </div>
          <BaseText inline>
            {{ $t('company.domain_settings_disclaimer') }}
          </BaseText>
          <BaseText
            inline
            :routerLink="{
              name: 'email-verification'
            }"
            :ml="0.25"
            v-test="'amazon-domain-settings-link'"
          >
            {{ $t('company.domain_settings') }}
          </BaseText>
        </div>
        <div v-else>
          <BaseInput
            v-model="formData.contactEmail"
            :label="$t('company.contact_email')"
            :info="$t('company.contact_email_info')"
            type="email"
            required
            v-test="'company-settings-contactEmail'"
          />
          <BaseSpinner v-if="loading" inline />
          <BaseText
            v-else
            inline
            link
            mb
            :mt="0.5"
            @click="handleEmailDomainClick"
            v-test="'domain-settings-link'"
          >
            {{ $t('company.domain_settings') }}
          </BaseText>
        </div>
      </BaseCard>

      <BaseCard :heading="$t('company.sms_settings')" :mb="2">
        <div :class="[$style.formRow, $style.mobileFull]">
          <BaseInput
            v-model="formData.smsSenderId"
            :label="$t('company.sms_message_sender')"
            :info="$t('company.sms_message_sender_info')"
            required
            :minLength="2"
            :maxLength="smsSenderIdMaxLength"
            mb
            v-test="'company-settings-smsSenderId'"
          />
          <BaseInput
            v-model="formData.smsNotificationNumber"
            :label="$t('company.sms_notifications_number')"
            :info="$t('company.sms_notifications_number_info')"
            type="tel"
            :maxLength="20"
            mb
            v-test="'company-settings-smsNotificationNumber'"
          />
        </div>
      </BaseCard>

      <BaseCard :heading="$t('company.social_information')">
        <BaseText mb>{{ $t('company.info_social_info') }}</BaseText>
        <div
          :class="[
            $style.row,
            {
              [$style.mediumScreen]: $screen != 'l'
            }
          ]"
        >
          <div :class="[$style.grow, $style.socialInput]">
            <BaseText>https://www.facebook.com/</BaseText>
            <div :class="$style.grow">
              <BaseInput
                v-model="formData.facebookId"
                :maxLength="255"
                v-test="'company-settings-facebookId'"
              />
            </div>
          </div>
          <img
            :class="$style.socialImage"
            src="https://static.salonized.com/images/company-info/profile_facebook_desc-b100fd299225cbd2c55b8a820a7520f0.png"
            alt="Facebook group"
          />
        </div>
        <div
          :class="[
            $style.row,
            {
              [$style.mediumScreen]: $screen != 'l'
            }
          ]"
        >
          <div :class="[$style.grow, $style.socialInput]">
            <BaseText>https://www.twitter.com/</BaseText>
            <div :class="$style.grow">
              <BaseInput
                v-model="formData.twitterId"
                :maxLength="255"
                v-test="'company-settings-twitterId'"
              />
            </div>
          </div>
          <img
            :class="$style.socialImage"
            src="https://static.salonized.com/images/company-info/profile_twitter_desc-a26a1bd99ae662064ef638edc26a5a67.png"
            alt="Twitter profile"
          />
        </div>
        <div
          :class="[
            $style.row,
            {
              [$style.mediumScreen]: $screen != 'l'
            }
          ]"
        >
          <div :class="[$style.grow, $style.socialInput]">
            <BaseText>https://www.instagram.com/</BaseText>
            <div :class="$style.grow">
              <BaseInput
                v-model="formData.instagramId"
                :maxLength="255"
                v-test="'company-settings-instagramId'"
              />
            </div>
          </div>
          <img
            :class="$style.socialImage"
            src="https://static.salonized.com/images/company-info/profile_instagram_desc-0498b9ad400fc6fc1dabd05083d4ecb0.png"
            alt="Instagram username"
          />
        </div>
      </BaseCard>

      <template #footer>
        <BaseButton
          :loading="isSubmitting"
          @click="submitForm"
          v-test="'company-settings-submit'"
        >
          {{ $t('global.actions.save') }}
        </BaseButton>
      </template>
    </PageWrap>
    <EmailDomainModal
      v-if="showEmailDomainModal"
      @close="showEmailDomainModal = false"
    />
  </ModuleBase>
</template>

<script lang="ts">
export default {
  name: 'CompanySettings'
};
</script>

<script lang="ts" setup>
import type { UpdateCompanyInput, BillingInfoAttributes } from '@/types';
import type { CloudinaryResponse } from '@/components/base-upload/index.vue';

import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

import unleash from '@/unleash';
import eventBus from '@/event-bus';
import { flash } from '@/helpers/ui';
import {
  COMPANY_PHONE_VALIDATION_REGEX,
  RSIN_NUMBER_VALIDATION_REGEX
} from '@/helpers/regex';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';

import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import EmailDomainModal from './EmailDomainModal.vue';
import VatIdSetting from './VatIdSetting.vue';

type UpdateCompanyFormData = UpdateCompanyInput & {
  billingInfo: Partial<BillingInfoAttributes>;
};

const { t } = useI18n();
const { company, updateCompany } = useCompanyStore();
const { hasFeatureFlag } = useUserStore();

const currentLogoImageUrl = ref<string | undefined>(
  company.logo?.thumbnail || undefined
);

const isSubmitting = ref(false);

const router = useRouter();
const showEmailDomainModal = ref(false);

const { result, loading, onResult } = useQuery(gql`
  query getCurrentUser {
    currentUser {
      company {
        billingInfo {
          billingEmail
        }
        emailDomain {
          id
        }
      }
    }
  }
`);

const hasEmailDomain = computed(
  () => !!result.value?.currentUser.company.emailDomain?.id
);

const handleEmailDomainClick = () => {
  if (hasEmailDomain.value) {
    if (unleash.isEnabled('AmazonSES-CustomDomain')) {
      router.push({ name: 'email-verification' });
    } else {
      router.push({ name: 'admin-company-communication' });
    }
  } else {
    showEmailDomainModal.value = true;
  }
};

const formData = reactive<UpdateCompanyFormData>({
  name: company.name,
  internalName: company.internalName,
  address: company.address,
  city: company.city,
  postalcode: company.postalcode,
  phone: company.phone,
  notificationEmail: company.notificationEmail,
  contactEmail: company.contactEmail,
  billingInfo: {
    billingEmail: company.billingInfo?.billingEmail
  },
  logoUrl: company.logoUrl,
  smsSenderId: company.smsSenderId,
  smsNotificationNumber: company.smsNotificationNumber,
  facebookId: company.facebookId,
  twitterId: company.twitterId,
  instagramId: company.instagramId,
  rsinNumber: company.rsinNumber
});

onResult(
  ({
    data: {
      currentUser: { company }
    }
  }) => {
    formData.billingInfo.billingEmail = company.billingInfo.billingEmail;
  }
);

const smsSenderIdMaxLength = computed(() =>
  formData?.smsSenderId?.match(/^\d+$/) ? 13 : 11
);

const v$ = useVuelidate();

const handleUpload = (uploadResults: CloudinaryResponse) => {
  formData.logoUrl = `${uploadResults.resource_type}/${uploadResults.type}/${uploadResults.public_id}.${uploadResults.format}`;
  currentLogoImageUrl.value = uploadResults.secure_url;
};

const { mutate: updateTask } = useMutation(gql`
  mutation updateOnboardingTask($input: UpdateOnboardingTaskInput!) {
    updateOnboardingTask(input: $input) {
      onboardingTask {
        id
        task
        completedAt
        claimedAt
      }
    }
  }
`);

const submitForm = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return;
  }

  isSubmitting.value = true;

  updateCompany(formData)
    .then(() => {
      updateTask({
        input: {
          task: 'SETUP_COMPANY_DETAILS',
          taskStatus: 'COMPLETE'
        }
      }).then(() => {
        eventBus.$emit('update-task', 'setup_company_details');
      });

      if (
        hasFeatureFlag('franchise-scope') &&
        company.internalName !== formData.internalName
      ) {
        eventBus.$emit('update-franchise-name', formData.internalName);
      }

      flash(t('global.flash.company_updated'));
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};
</script>

<style lang="scss" module>
.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
  }

  &.mobileFull {
    .base.smallScreen & {
      & > * {
        width: 100%;
      }
    }
  }
}

.grow {
  flex-grow: 1;
  align-self: stretch;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
  flex-wrap: wrap;

  & > * {
    margin-right: $spacing;
  }

  &.mediumScreen {
    flex-direction: column;
    align-items: baseline;

    & > * {
      margin-bottom: $spacing;
    }
  }
}

.logoPlaceholder {
  opacity: 0.6;
  width: 120px;
  height: 120px;
  fill: $color-text;
  border: 1px solid $color-text;
  padding: 20px;
}

.socialInput {
  display: flex;
  align-items: center;
  align-self: stretch;

  & > *:first-child {
    margin-right: $spacing;
    width: 180px;
  }
}

.socialImage {
  width: 270px;
}

.contactEmailContainer {
  position: relative;
}

.contactEmailLink {
  position: absolute;
  right: 0;
  top: 0;
}

.contactEmailIcon {
  margin-right: $spacing;
}
</style>
