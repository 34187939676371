import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { useCompanyStore } from '@/stores/company';

export const useTreatwellStoreStore = defineStore('treatwellStore', () => {
  const { company } = useCompanyStore();
  const storeCartItemCount = useStorage(
    `store-cart-item-count-${company.id}`,
    0
  );

  return {
    storeCartItemCount
  };
});
